import React from 'react';
import { BsGearFill } from 'react-icons/bs';
import { rolesOptions } from '../../../constants/constants';
import styles from './UserRow.module.scss';

export default function UserRow({ user, editUser }) {
  const profile = user?.user?.profile;
  return (
    <div
      className={styles.container}>
        <p>
        {profile?.lastName?.toUpperCase()} {profile?.firstName}
        </p>
        <p className={styles.admin}>
          {rolesOptions?.find((r) => r.value === user?.role)?.label}
        </p>
      <button
        onClick={() => editUser(user)}
        className={styles.icon}>
        <BsGearFill size={28} />
      </button>
    </div>
  );
}
