import {
  MESSAGE_VISUALISATION,
  POST_VISUALISATION_DATA,
  ERROR_VISUALISATION,
  SET_VISUALISATION_FILTERS,
  GET_QUESTIONS,
  PUT_QUESTION,
  POST_QUESTION,
  GET_SYNTHESIS,
  GET_FREQUENTATION,
  GET_CA,
  GET_EXPENSE_VIZ,
  GET_FILLING_VIZ,
  GET_CONSUMPTION_VIZ,
} from '../actions/types';

const year = new Date().getFullYear();
const prevYear = new Date().getFullYear() - 1;

const initialState = {
  error: null,
  message: null,
  data: null,
  questions: null,
  filters: {
    isMonth: false,
    structures: [],
    y1: prevYear.toString(),
    y0: year.toString(),
    m0: '',
    m1: '',
  },
  synthesis: null,
  frequentation: null,
  ca: null,
  expenses: null,
  filling: null,
  consumption: null,
};

export default function visualisationReducer(state = initialState, action) {
  const questions = state.questions ? [...state.questions] : [];
  switch (action.type) {
    case POST_QUESTION:
      return { ...state, questions: [...questions, action.payload] };
    case PUT_QUESTION:
      return {
        ...state,
        questions: [...questions.filter((q) => q._id !== action.payload._id), action.payload],
      };
    case GET_QUESTIONS:
      return { ...state, questions: action.payload };
    case MESSAGE_VISUALISATION:
      return { ...state, message: action.payload };
    case POST_VISUALISATION_DATA:
      return { ...state, data: action.payload };
    case GET_SYNTHESIS:
      return { ...state, synthesis: action.payload };
    case GET_FREQUENTATION:
      return { ...state, frequentation: action.payload };
    case GET_EXPENSE_VIZ:
      return { ...state, expenses: action.payload };
    case GET_CONSUMPTION_VIZ:
      return { ...state, consumption: action.payload };
    case GET_FILLING_VIZ:
      return { ...state, filling: action.payload };
    case SET_VISUALISATION_FILTERS:
      return { ...state, filters: action.payload };
    case GET_CA:
      return { ...state, ca: action.payload };
    case ERROR_VISUALISATION:
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
}
