import React, { useState, useEffect } from 'react';
import { MdAccessibility } from 'react-icons/md';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import styles from './SaleRow.module.scss';

export default function SaleRow({
  monthData,
  index,
  handleChange,
}) {
  const {
    config,
  } = useSelector((state) => state.structures);
  const [value, setValue] = useState(monthData.value);
  const [percentage, setPercentage] = useState(null);
  const isActive = config?.year === new Date().getFullYear().toString()
    && index === new Date().getMonth();

  function onChange(key, values) {
    const val = parseFloat(values.floatValue);
    setValue({ ...value, [key]: val });
    handleChange(`content.${monthData.key}.${key}`, val);
  }

  function calcPercentage() {
    if (
      !monthData?.provisional?.territory
      || !monthData?.provisional?.outsideTerritory) {
      return setPercentage(null);
    }
    let result = 0;
    const provisional = {
      territory: monthData.provisional.territory ? monthData.provisional.territory : 0,
      outsideTerritory: monthData.provisional.outsideTerritory
        ? monthData.provisional.outsideTerritory
        : 0,
    };
    const reel = {
      territory: value?.territory ? value.territory : 0,
      outsideTerritory: value?.outsideTerritory
        ? value.outsideTerritory
        : 0,
    };
    const provisionalValue = provisional.territory + provisional.outsideTerritory;
    const reelValue = reel.territory + reel.outsideTerritory;
    result = ((reelValue - provisionalValue) / provisionalValue) * 100;
    return setPercentage(result);
  }

  const percentageValue = useSpring({ number: percentage || 0 });

  useEffect(() => {
    if (!monthData.isProvisional) {
      calcPercentage();
    }
  }, [value?.outsideTerritory, value?.territory]);

  return (
    <div
      className={`${styles.container} ${isActive ? styles.active : ''}`}
      style={{ color: value?.territory === null && value?.outsideTerritory === null ? '#B1B1BF' : '#181844' }}
      >
      <div className={styles.label}>
        <p>
          {monthData.isProvisional && <span>Prévisionnel</span>}
          {percentage && <span
            style={{ color: percentage >= 0 ? '#33C597' : '#C5334A' }}>
            <animated.span>{percentageValue.number.to((x) => x.toFixed(0))}</animated.span> %
          </span>
          }
        </p>
        <p>{monthData.label}</p>
      </div>
      <div className={styles.input}>
        <NumberFormat
          style={{ color: value?.territory >= 0 ? '#181844' : '#B1B1BF' }}
          value={value?.territory !== null ? Number(value?.territory) : 0 }
          defaultValue={0}
          thousandSeparator={' '}
          allowedDecimalSeparators={['.', ',']}
          decimalSeparator={','}
          onValueChange={(values) => onChange('territory', values)}
        />
        <div className={styles.unit}>
          {monthData.unit === 'person'
            ? <>
              <MdAccessibility size={24}/>
            </>
            : <>
              {monthData.unit}
            </>
          }
        </div>
      </div>

      <div className={styles.input}>
        <NumberFormat
          style={{ color: value?.outsideTerritory >= 0 ? '#181844' : '#B1B1BF' }}
          value={value?.outsideTerritory !== null ? Number(value?.outsideTerritory) : 0 }
          defaultValue={0}
          thousandSeparator={' '}
          allowedDecimalSeparators={['.', ',']}
          decimalSeparator={','}
          onValueChange={(values) => onChange('outsideTerritory', values)}
        />
        <div className={styles.unit}>
        {monthData.unit === 'person'
          ? <>
            <MdAccessibility size={24}/>
          </>
          : <>
            {monthData.unit}
          </>
        }
        </div>
      </div>

    </div>
  );
}
