import React from 'react';
// import { calcPercentage } from '../../../../utils/utils';
import PieChart from '../../../lib/PieChart/PieChart';

import styles from './FrequentationRatio.module.scss';

export default function FrequentationRatio({
  data,
  label,
  colors,
}) {
  function calcRatio(value) {
    if (!data) return null;
    return (value * 100) / data.reduce((a, b) => a.value + b.value);
  }

  const Tooltip = (d) => (
    <div
      style={{
        padding: 12,
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Montserrat-SemiBold',
        background: '#FFFFFF',
        borderRadius: 3,
        boxShadow: '0px 10px 28px #b8b8ba',
      }}
      >
      <div style={{
        width: 20,
        height: 20,
        backgroundColor: d.datum.color,
        marginRight: 10,
        borderRadius: 3,
      }}>
      </div>
      <strong>
        <span style={{ color: '#181943' }}>{d.datum.label}</span> : <br/><span style={{ color: d.datum.color }}>{calcRatio(d.datum.value).toFixed(2)} %</span>
      </strong>
    </div>
  );
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <p>{label}</p>
      </div>
      <div className={styles['chart-container']}>
        <div className={styles.chart}>
          <PieChart
            data={data}
            tooltip={Tooltip}
            colors={(d) => colors[d.id]}
          />
        </div>
        <div className={styles.legend}>
          <div>
            {data.map((d) => <div
              className={styles.item}
              key={d.id}>
              <label>{d.label}</label>
              <p style={{ color: colors[d.id] }}>{calcRatio(d.value).toFixed(2)} %</p>
            </div>)}
          </div>
        </div>
      </div>
    </div>
  );
}
