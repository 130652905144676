import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import styles from './input-text.module.scss';

const InputText = ({
  name,
  control,
  rules,
  widthLabel = null,
  inline = false,
  label = null,
  placeholder,
  password = false,
  className = null,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
  <>
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: {
          value = '', ref, onChange, onBlur,
        },
      }) => (
          <div className={inline ? `${styles['container-input']} ${className ? styles[className] : ''} ${styles.inline}` : `${styles['container-input']} ${className ? styles[className] : ''}`}>
            { label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
            <input
              ref={ref}
              type={password && !showPassword ? 'password' : 'text'}
              value={value}
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              className={styles['input-text']}
              placeholder={placeholder}
            />
            {password
              && <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword
                  ? <AiOutlineEye size={20}/>
                  : <AiOutlineEyeInvisible size={20} />
                }
              </button>
            }
          </div>
      )}
    />
  </>
  );
};

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  label: PropTypes.string,
  inline: PropTypes.bool,
  widthLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputText;
