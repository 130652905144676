import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { AiFillDelete } from 'react-icons/ai';

import { rightsOptions } from '../../../constants/constants';

import {
  postUserAction,
  putUserAction,
  putStructureAction,
  getUsersAction,
  deleteAdminAction,
} from '../../../actions/structuresActions';

import InputEmail from '../../lib/InputEmail';
import InputText from '../../lib/InputText';
import CustomSelect from '../../lib/Select';
import ErrorField from '../../lib/ErrorField';
import Button from '../../lib/Button';

import styles from './UserModale.module.scss';

export default function UsersModale({
  structure,
  userData,
  setUserData,
}) {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.admin);
  const admins = users;
  const { user, error, usersList } = useSelector((state) => state.structures);
  const { toast } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const usersOptions = usersList?.map((u) => ({ label: u?.profile?.lastName, value: u }));
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm();

  function submitUser(values) {
    setIsLoading(true);
    if (structure) {
      if (!userData?.user?._id) {
        postUserAction(dispatch, values);
      } else {
        if (userData.role !== watch('role')) {
          putStructureAction(dispatch, structure._id, {
            users: [
              ...structure.users.filter((u) => u._id !== userData._id),
              { user: userData?.user._id, role: watch('role') },
            ],
          });
        }
        const obj = values;
        delete obj.role;
        putUserAction(dispatch, userData?.user?._id, obj);
      }
    } else {
      const obj = values;
      obj.role = 'Admin';
      if (!userData?.user?._id) {
        postUserAction(dispatch, obj);
      } else {
        putUserAction(dispatch, userData?.user?._id, obj);
      }
    }
  }

  function selectUser(userSelected) {
    setUserData({ user: userSelected });
  }

  function deleteFromStructureUser(id) {
    putStructureAction(dispatch, structure._id, {
      users: structure.users.filter((u) => u._id !== id),
    });
    setUserData();
  }

  async function deleteAdmin(id) {
    await deleteAdminAction(dispatch, id);
    setUserData();
  }

  useEffect(() => {
    getUsersAction(dispatch);
  }, []);

  useEffect(() => {
    if (userData.user?.profile) {
      setValue('profile', userData.user?.profile);
    }
    if (userData.user?.email) {
      setValue('email', userData.user?.email);
    }
    if (userData.role) {
      setValue('role', userData.role);
    }
  }, [userData]);

  useEffect(() => {
    if (toast?.message) {
      setIsLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    if (user?._id && structure?.users) {
      putStructureAction(dispatch, structure._id, { users: [...structure.users, { user: user._id, role: watch('role') }] });
    }
  }, [user]);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={`${styles.col} ${styles.user}`}>
          {structure && usersList
          && <CustomSelect
            options={usersOptions}
            value={userData}
            className={'primary'}
            placeholder='Rechercher un utilitsateur'
            handleChange={(val) => selectUser(val)}
            />
          }
        </div>
        <div className={styles.col}>
          <InputText
            name='profile.lastName'
            label='Nom'
            control={control}
            className={'primary'}
            rules={{ required: 'le nom est requis' }}
            placeholder={'Nom'}
            />
          <ErrorField message={errors.profile?.lastName?.message}/>
        </div>
        <div className={styles.col}>
          <InputText
            name='profile.firstName'
            label='Prénom'
            control={control}
            className={'primary'}
            rules={{ required: 'le prénom est requis' }}
            placeholder={'Prénom'}
            />
          <ErrorField message={errors.profile?.firstName?.message}/>
        </div>
        <div className={styles.col}>
          <InputEmail
            name='email'
            label='Email'
            control={control}
            className={'primary'}
            required
            placeholder={'email'}
          />

          {!errors.email && error?.data?.error === 'That email address is already in use.'
            ? <ErrorField message='Cette adresse email est déjà utilisée.' />
            : <ErrorField message={errors.email?.message}/>
          }
        </div>
        {structure
        && <div className={styles.col}>
          <Controller
            control={control}
            name="role"
            options={rightsOptions}
            defaultValue={'Member'}
            rules={{ required: 'le role est requis' }}
            render={() => <CustomSelect
              label={'Droit'}
              options={rightsOptions}
              className={'primary'}
              handleChange={(val) => setValue('role', val)}
              value={watch('role')}
              />
            }
          />
          <ErrorField message={errors.role?.message}/>
        </div>
        }
      </div>
      <Button
        isLoading={isLoading}
        handleClick={handleSubmit(submitUser)}
        >
        {userData?._id && user?._id ? "Modifier l'utilisateur" : "Enregistrer l'utilisateur"}
      </Button>
      {structure?.users.find((u) => u.user._id === userData?.user?._id)
        && <button
            onClick={() => deleteFromStructureUser(userData._id)}
          className={styles.delete}>
          <AiFillDelete size={20}/> <p>Supprimer l'utilitsateur de la structure</p>
        </button>
      }
      {admins?.find((u) => u?._id === userData?.user?._id)
        && <button
            onClick={() => deleteAdmin(userData?.user?._id)}
          className={styles.delete}>
          <AiFillDelete size={20}/> <p>Supprimer l'utilitsateur</p>
        </button>
      }
    </div>
  );
}
