import React, {
  useRef, useState, useEffect,
} from 'react';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';

import FillingSubType from './FillingSubType/FillingSubType';

import styles from './FillingType.module.scss';

export default function FillingType({
  subTypes,
  name,
}) {
  const contentRef = useRef(null);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [initPanelHeight, setInitPanelHeight] = useState();
  const [panelHeight, setPanelHeight] = useState();

  useEffect(() => {
    if (!initPanelHeight && contentRef?.current?.clientHeight) {
      setInitPanelHeight(contentRef?.current?.clientHeight);
      setPanelHeight(contentRef?.current?.clientHeight);
    }
  }, [contentRef?.current?.clientHeight]);

  return (
    <div className={`${styles.container} ${panelIsOpen ? styles['is-open'] : ''}`}>
      <div className={styles.title}>
        <h2>{name}</h2>
        <button
          onClick={() => setPanelIsOpen(!panelIsOpen)}
          >
          {panelIsOpen ? <RiArrowUpSLine size={28} color={'#192243'}/> : <RiArrowDownSLine size={28} color={'#192243'}/>}
        </button>
      </div>
      <div
        className={styles.panel}
        style={{
          height: panelIsOpen && panelHeight > 0
            ? panelHeight : 0,
        }}>
        <div ref={contentRef}>
          <div className={styles.content}>
            {subTypes?.map((s) => <FillingSubType
              key={s._id}
              subType={s}
              name={s.subType}
              panelHeight={panelHeight}
              setPanelHeight={setPanelHeight}
            />)}
          </div>
        </div>
      </div>
    </div>
  );
}
