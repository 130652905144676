import React, { useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { MdAccessibility } from 'react-icons/md';
import { useSelector } from 'react-redux';
import styles from './MonthRow.module.scss';

export default function MonthRow({
  monthData,
  handleChange,
  index,
}) {
  const inputRef = useRef();
  const {
    config,
  } = useSelector((state) => state.structures);
  const [value, setValue] = useState(monthData.value);
  const isActive = config?.year === new Date().getFullYear().toString()
    && index === new Date().getMonth();

  function onChange(values) {
    const val = parseFloat(values.floatValue);
    setValue(Number.isNaN(val) ? '' : val);
    handleChange(values.floatValue);
  }

  return (
    <div
      onClick={() => inputRef.current.focus()}
      className={`${styles.container} ${isActive ? styles.active : ''}`}
      style={{ color: value === '' ? '#B1B1BF' : '#181844' }}
      >
      <div className={styles.label}>
        <p>
          {monthData.isProvisional && <span>Prévisionnel</span>}
        </p>
        <p>{monthData.label}</p>
      </div>
      <div className={styles.input}>
        {monthData
          && <NumberFormat
            getInputRef={inputRef}
            style={{ color: value === '' ? '#B1B1BF' : '#181844' }}
            value={value ? Number(value) : null }
            thousandSeparator={' '}
            allowedDecimalSeparators={['.', ',']}
            decimalSeparator={','}
            onValueChange={(values) => onChange(values)}
          />
        }
      </div>
      <div className={styles.unit}>
        {monthData.unit === 'person'
          ? <>
            <MdAccessibility size={24}/>
          </>
          : <>
            {monthData.unit}
          </>
        }
      </div>
    </div>
  );
}
