import {
  ERROR_ADMINS,
  MESSAGE_ADMINS,
  GET_ADMIN_USERS,
  POST_ADMIN_USER,
  PUT_ADMIN_USER,
  DELETE_ADMIN_USER,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  users: null,
};

export default function adminReducer(state = initialState, action) {
  const users = state.users || [];
  const userIndex = users.findIndex((u) => u._id === action?.payload?._id);
  switch (action.type) {
    case ERROR_ADMINS:
      return { ...state, message: action.payload };
    case MESSAGE_ADMINS:
      return { ...state, error: action.payload };
    case GET_ADMIN_USERS:
      return { ...state, users: action.payload };
    case POST_ADMIN_USER:
      return { ...state, users: [action.payload, ...users] };
    case DELETE_ADMIN_USER:
      return { ...state, users: users.filter((u) => u._id !== action?.payload) };
    case PUT_ADMIN_USER:
      if (userIndex !== -1) {
        users[userIndex] = action.payload;
      }
      return { ...state, users: [...users] };
    default:
      return { ...state };
  }
}
