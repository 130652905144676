import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { AiFillDelete } from 'react-icons/ai';
import { API_URL } from '../../../actions';

import { postFileAction, deleteFileAction, putStructureAction } from '../../../actions/structuresActions';

import Button from '../../lib/Button';
import CustomSelect from '../../lib/Select';
import InputFile from '../../lib/InputFile/InputFile';

import styles from './DocumentModale.module.scss';
import { ERROR_STRUCTURE, POST_FILE } from '../../../actions/types';

export default function DocumentModale({
  setModaleIsActive,
  type,
}) {
  const { file, error, structuresList } = useSelector((state) => state.structures);
  const dispatch = useDispatch();
  const {
    handleSubmit, errors, register, setValue, control,
  } = useForm();

  const [uploadedFile, setUploadedFile] = useState();
  const [fileError, setFileError] = useState();
  const [fileId, setFileId] = useState();
  const [structure, setStructure] = useState();

  const clearFile = useCallback(() => {
    dispatch({
      type: POST_FILE,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    register('file');
  }, [register]);

  useEffect(() => {
    clearFile();
    return () => {
      clearFile();
    };
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (errors?.file?.message) {
      setFileError(errors.file.message);
    }
  }, [errors?.file]);

  useEffect(() => {
    if (file && file._id) {
      setValue('file', file._id);
      setFileId(file._id);
      if (uploadedFile) document.getElementById('file').value = '';
      setUploadedFile();
      setFileError();
    }
    // eslint-disable-next-line
  },[file]);

  useEffect(() => {
    if (error && error.data && error.data.error) {
      if (error.data.error === 'Extension not allowed.') {
        setFileError("Ce type de fichier n'est pas autorisé");
        setUploadedFile();
        setFileId();
        dispatch({
          type: 'ERROR_STUDIES',
          payload: null,
        });
      }
    }
    // eslint-disable-next-line
  },[error]);

  function handleChangeFile(fileData) {
    dispatch({
      type: ERROR_STRUCTURE,
      payload: null,
    });

    setUploadedFile(fileData);
    setFileError();
    setFileId();
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('title', fileData.name);
    formData.append('description', `document ${fileData.name}`);
    formData.append('alt', `${fileData.name.split(' ').join('-')}-file`);
    postFileAction(dispatch, formData);
  }

  function deleteFile(id) {
    deleteFileAction(dispatch, id);
    setFileId();
  }

  function closeModale() {
    if (fileId) deleteFile(fileId);
    setModaleIsActive(false);
  }

  function submitFiles() {
    setModaleIsActive(false);
    putStructureAction(
      dispatch,
      structure?._id,
      { files: [...structure.files, { type, file: file?._id }] },
    );
  }

  return (
    <div className={styles.container}>
      <h2>Ajouter un document</h2>
        <form>
         <CustomSelect
          label={'Site'}
          defaultValue={null}
          handleChange={(val) => setStructure(val)}
          options={structuresList.map((s) => ({ value: s, label: s.name }))}
          />

          {file && file._id
            && <>
              <label>Fichier</label>
              <div className={styles.file}>
                <a href={`${API_URL}/${file.path}`} rel="noreferrer" target="_blank">
                  <div className={styles.link}>
                    <p>{file.name}</p>
                  </div>
                </a>
                <button
                  type="button"
                  onClick={() => deleteFile(fileId)}
                  className={`${styles.delete}`}>
                  <AiFillDelete size={20} color={'red'} />
                  <p>supprimer</p>
                </button>
              </div>
            </>
          }
          {!fileId && structure
            && <Controller
              name="file"
              rules={{ required: 'Le ficher est requis' }}
              control={control}
              defaultValue={null}
              render={() => <InputFile
                  name="file"
                  isLoading={uploadedFile}
                  withErrors={true}
                  defaultValue={null}
                  onChange={(files) => handleChangeFile(files[0])}
                  error={fileError}
                  />}
            />
          }

          {structure?._id && file
            && <div className={styles.buttons}>
              <Button
                handleClick={handleSubmit(submitFiles)}
                primary
                >
                Valider
              </Button>
              <Button
                primary
                handleClick={() => closeModale()}
                >
                Annuler
              </Button>
            </div>
          }
        </form>
    </div>
  );
}
