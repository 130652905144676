import React from 'react';
import { useTheme } from '@nivo/core';

import BarChart from '../../../lib/BarChart/BarChart';

import styles from './ConsumptionCharts.module.scss';
import { numberWithSpaces } from '../../../../utils/utils';

export default function ConsumptionCharts({
  data,
  indexBy,
  label,
  colors,
  margin = {
    top: 45, right: 10, bottom: 40, left: 80,
  },
  enableLabel = false,
  padding = 0.3,
  legend,
  period = 'year',
  keys,
}) {
  const isWaterByPerson = label === 'EAU / BAIGNEUR';

  const renderLegendLabel = (key) => {
    if (key === 'Année N-2' && period !== 'year') {
      return 'Mois -2';
    }
    if (key === 'Année N-1' && period !== 'year') {
      return 'Mois -1';
    }
    return key;
  };

  const getValues = (key) => {
    switch (key) {
      case 'Gaz':
        return {
          unit: 'Mwh',
          min: 0,
          max: 1200,
        };

      case 'Électricité':
        return {
          unit: 'Mwh',
          min: 0,
          max: 1200,
        };

      case 'Bois':
        return {
          unit: 'Tonnes',
          sortUnit: 'T',
          min: 0,
          max: 100,
        };
      case 'Eau':
        if (label === 'EAU / BAIGNEUR') {
          return {
            unit: 'm3',
            min: 'auto',
            max: 'auto',
          };
        }

        return {
          unit: 'm3',
          min: 8000,
          max: 25000,
        };

      default:
        return {
          unit: 'm3',
          min: 'auto',
          max: 'auto',
        };
    }
  };
  const CustomTickBottom = (tick) => {
    const theme = useTheme();
    return (
      <g transform={`translate(${-40},${tick.y + 30})`}>
        <text
          textAnchor="left"
          dominantBaseline="middle"
          style={{
            ...theme.axis.ticks.text,
            fill: '#181844',
            fontSize: 14,
            fontFamily: 'Montserrat-SemiBold',
          }}
        >
          {!isWaterByPerson
            && <>
          {tick.value.toUpperCase()} ({getValues(tick.value).unit})
          </>
         }
        </text>
      </g>
    );
  };

  const CustomTickLeft = (tick, k) => {
    const theme = useTheme();
    return (
      <g transform={`translate(${tick.x - 10},${tick.y})`}>
        <text
          textAnchor="end"
          dominantBaseline="middle"
          style={{
            ...theme.axis.ticks.text,
            fill: '#A1A0AF',
            fontSize: 14,
            fontFamily: 'Montserrat-Regular',
          }}
        >
        {tick.value} {getValues(k).sortUnit}
        </text>
      </g>
    );
  };
  const Tooltip = (d) => (
    <div
      style={{
        padding: 8,
        zIndex: 100,
        color: '#181844',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Montserrat-SemiBold',
        background: '#FFFFFF',
        borderRadius: 3,
        boxShadow: '0px 10px 28px #b8b8ba',
      }}
      >
      <div style={{
        width: 20,
        height: 20,
        backgroundColor: d.color,
        marginRight: 10,
        borderRadius: 3,
      }}>

      </div>
      <strong>
        <span style={{ color: '#181844' }}>{renderLegendLabel(d.id)} : {numberWithSpaces(d.value)} {getValues(d.indexValue).unit}</span><br/>
      </strong>
    </div>
  );

  function objectToArray(obj) {
    const array = [];
    array.push(obj);
    return array;
  }

  return (
    <div className={`${styles.container} ${isWaterByPerson ? styles['water-person'] : ''}`}>
      <div className={styles.row}>
        <p>{label}</p>
        {legend
          && <div className={styles.legend}>
            {keys?.map((k) => (
              <div
                key={k}
                className={styles.key}>
                <div
                  className={styles.square}
                  style={{
                    backgroundColor: colors[k],
                  }}
                  >
                </div>
                <p>{renderLegendLabel(k)}</p>
              </div>
            ))}
          </div>
        }
      </div>
      <div className={styles['chart-container']}>
        <div
          className={styles.chart}
          style={{
            minWidth: '100%',
            width: label === 'EAU / BAIGNEUR' ? '100%' : data.length * 160,
          }}
        >
          {data.map((c) => <div
              className={styles.col}
              style={{ width: `${100 / data.length}%` }}
              key={c.label}
              >
              <BarChart
                indexBy={indexBy}
                data={objectToArray(c)}
                margin={margin}
                keys={keys}
                padding={padding}
                enableLabel={enableLabel}
                // minValue={getValues(c.label).min}
                // maxValue={getValues(c.label).max}
                axisBottom={{
                  renderTick: CustomTickBottom,
                }}
                axisLeft={{
                  tickValues: 6,
                  renderTick: (ti) => CustomTickLeft(ti, c.label),
                }}
                tooltip={(t) => Tooltip(t)}
                colors={(d) => colors[d.id]}
              />
            </div>)}
        </div>
      </div>
    </div>
  );
}
