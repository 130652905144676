import React from 'react';
import useDimensions from 'react-use-dimensions';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalRouter from './pages/GlobalRouter';
import Mobile from './pages/Mobile/Mobile';

function App() {
  const [ref, { width, height }] = useDimensions();
  return (
    <div ref={ref} className="app-container">
      { width >= 1024 && height >= 600
        ? <Router>
          <GlobalRouter />
        </Router>
        : <Mobile/>
      }
    </div>
  );
}

export default App;
