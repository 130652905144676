import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import styles from './PieChart.module.scss';

export default function PieChart({
  data,
  colors = { scheme: 'nivo' },
  tooltip = null,
}) {
  return (
    <div className={styles.container}>
      <ResponsivePie
        data={data}
        margin={{
          top: 20, right: 20, bottom: 20, left: 20,
        }}
        startAngle={-180}
        colors={colors}
        colorBy={'id'}
        tooltip={tooltip}
        activeOuterRadiusOffset={15}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsSkipAngle={10}
        legends={[]}
        />
    </div>
  );
}
