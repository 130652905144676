import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SET_CONFIG, POST_STRUCTURE_DATA } from '../../../actions/types';
import { postStructureDataAction, putPayrollAction } from '../../../actions/formsActions';

import MonthRow from '../../../components/partials/MonthRow/MonthRow';

import { months } from '../../../constants/constants';

import styles from './Payroll.module.scss';

export default function Payroll() {
  const {
    config, data,
  } = useSelector((state) => state.structures);
  const dispatch = useDispatch();

  const payrollsData = data?.filter((d) => d.isProvisional === config.isProvisional)[0];

  const [payrolls, setPayrolls] = useState();
  const [form, setForm] = useState({});

  const autoSaveTimer = useRef();

  function saveWithTimer(value, id) {
    clearTimeout(autoSaveTimer.current);
    autoSaveTimer.current = setTimeout(() => putPayrollAction(dispatch, id, value),
      3000);
    return null;
  }

  function handleChange(key, value) {
    const formData = { ...form, [key]: value };
    setForm(formData);
    saveWithTimer(formData, payrollsData?._id);
  }

  const initConfig = useCallback(() => {
    dispatch({
      type: SET_CONFIG,
      payload: { ...config, type: 'payroll' },
    });
    dispatch({
      type: POST_STRUCTURE_DATA,
      payload: null,
    });
  }, [dispatch]);

  const getData = useCallback(() => {
    postStructureDataAction(dispatch, config);
  }, [dispatch, config]);

  useEffect(() => {
    initConfig();
  }, []);

  useEffect(() => {
    if (config?.structure && config?.year && config?.type === 'payroll') {
      getData();
      setPayrolls();
    }
  }, [config?.year, config?.structure, config?.type, config?.isProvisional]);

  useEffect(() => {
    if (payrollsData?.content) {
      const list = [];
      Object.entries(payrollsData.content).forEach(([key, value]) => {
        list.push({
          _id: `${payrollsData._id}-${key}`,
          key: `content.${key}`,
          label: months[key],
          isProvisional: payrollsData?.isProvisional,
          unit: '€',
          value,
        });
      });
      setForm({});
      setPayrolls(list);
    }
  }, [payrollsData]);

  return (
    <div className={`page-content ${styles.container}`}>
      {payrolls && <div className={styles.content}>
        <div className={styles.labels}>
          <div className={styles.label}>
          </div>
          <div className={styles.label}>
            <p>Masse salariale</p>
          </div>
        </div>
        {payrolls?.map((m) => <MonthRow
          key={m._id}
          monthData={m}
          handleChange={(value) => handleChange(m.key, value)}
        />)}
      </div>
      }
    </div>
  );
}
