import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SET_CONFIG, POST_STRUCTURE_DATA } from '../../../actions/types';
import { postStructureDataAction } from '../../../actions/formsActions';

import FrequentationYear from '../../../components/partials/FrequentationYear/FrequentationYear';

import styles from './Frequentation.module.scss';

export default function Frequentation() {
  const {
    config, data,
  } = useSelector((state) => state.structures);
  const dispatch = useDispatch();

  const initConfig = useCallback(() => {
    dispatch({
      type: SET_CONFIG,
      payload: { ...config, type: 'frequentation' },
    });
    dispatch({
      type: POST_STRUCTURE_DATA,
      payload: null,
    });
  }, [dispatch]);

  const getData = useCallback(() => {
    postStructureDataAction(dispatch, config);
  }, [dispatch, config]);

  useEffect(() => {
    initConfig();
  }, []);

  useEffect(() => {
    if (config?.structure && config?.year && config?.type === 'frequentation') {
      getData();
    }
  }, [config.year, config?.structure, config?.type, config?.isProvisional]);

  const frequentations = data?.filter((d) => d.isProvisional === config.isProvisional);

  return (
    <div className={`page-content ${styles.container}`}>
      {frequentations?.map((c) => <FrequentationYear
        key={c._id}
        id={c._id}
        name={c.type}
        isProvisional={c.isProvisional}
        provisionalContent={
          data?.find((d) => d.isProvisional && !c.isProvisional && d.type === c.type)?.content
        }
        content={c.content}
      />)}
    </div>
  );
}
