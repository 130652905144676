// AUTH ACTIONS
export const ERROR_AUTH = 'ERROR_AUTH';
export const USER_AUTH = 'USER_AUTH';
export const MESSAGE_AUTH = 'MESSAGE_AUTH';
export const SET_TOAST = 'SET_TOAST';
export const SET_ROLE = 'SET_ROLE';
// STRUCTURE ACTIONS
export const ERROR_STRUCTURE = 'ERROR_STRUCTURE';
export const GET_STRUCTURES = 'GET_STRUCTURES';
export const GET_STRUCTURE = 'GET_STRUCTURE';
export const POST_STRUCTURE = 'POST_STRUCTURE';
export const SET_CONFIG = 'SET_CONFIG';
export const RESET_CONFIG = 'RESET_CONFIG';
export const POST_USER = 'POST_USER';
export const GET_USERS = 'GET_USERS';
export const GET_FILES = 'GET_FILES';
export const POST_FILE = 'POST_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const MESSAGE_STRUCTURE = 'MESSAGE_STRUCTURE';
export const EXPORT_IS_LOADING = 'EXPORT_IS_LOADING';
export const GET_LISTS = 'GET_LISTS';

export const ERROR_ADMINS = 'ERROR_ADMINS';
export const MESSAGE_ADMINS = 'MESSAGE_ADMINS';
export const GET_ADMIN_USERS = 'GET_ADMIN_USERS';
export const POST_ADMIN_USER = 'POST_ADMIN_USER';
export const PUT_ADMIN_USER = 'PUT_ADMIN_USER';
export const DELETE_ADMIN_USER = 'DELETE_ADMIN_USER';
// FORM ACTIONS
export const PUT_CONSUMPTION = 'PUT_CONSUMPTION';
export const POST_STRUCTURE_DATA = 'POST_STRUCTURE_DATA';
export const PUT_SALE = 'PUT_SALE';
export const PUT_PAYROLL = 'PUT_PAYROLL';
export const POST_EXPENSE = 'POST_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const PUT_FREQUENTATION = 'PUT_FREQUENTATION';
export const PUT_FILLING = 'PUT_FILLING';

// VISUALISATION ACTIONS
export const MESSAGE_VISUALISATION = 'MESSAGE_VISUALISATION';
export const SET_PERIOD = 'SET_PERIOD';
export const POST_VISUALISATION_DATA = 'POST_VISUALISATION_DATA';
export const SET_VISUALISATION_STRUCTURES = 'SET_VISUALISATION_STRUCTURES';
export const ERROR_VISUALISATION = 'ERROR_VISUALISATION';
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const PUT_QUESTION = 'PUT_QUESTION';
export const POST_QUESTION = 'POST_QUESTION';
export const GET_SYNTHESIS = 'GET_SYNTHESIS';
export const GET_FREQUENTATION = 'GET_FREQUENTATION';
export const GET_CA = 'GET_CA';
export const GET_EXPENSE_VIZ = 'GET_EXPENSE_VIZ';
export const GET_FILLING_VIZ = 'GET_FILLING_VIZ';
export const GET_CONSUMPTION_VIZ = 'GET_CONSUMPTION_VIZ';
export const SET_VISUALISATION_FILTERS = 'SET_VISUALISATION_FILTERS';
