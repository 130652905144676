import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useSelector, useDispatch } from 'react-redux';
import logo from '../../assets/images/logo_white.png';

import { resetPasswordAction } from '../../actions/authActions';
import InputText from '../../components/lib/InputText';
import ErrorField from '../../components/lib/ErrorField';
import Button from '../../components/lib/Button';
import styles from './Auth.module.scss';

export default function Login() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const { error, message } = useSelector((state) => state.auth);
  const {
    control,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm();

  const clearError = useCallback(() => {
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(false);

  function onSubmit(values) {
    setIsLoading(true);
    clearError();
    resetPasswordAction(dispatch, token, values);
  }

  useEffect(() => {
    clearError();
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [error, message]);

  return (
    <div className={styles.container}>
      <form>
        <div className={styles.logo}>
          <img
            src={logo}
            alt="logo"
          />
        </div>
        <InputText
          name='password'
          label='Mot de passe'
          control={control}
          className={'login'}
          rules={{ required: 'le mot de passe est requis' }}
          password
          placeholder={'mot de passe'}
          />
        <ErrorField message={errors.password?.message}/>
        <div className={`${styles['btn-container']} ${styles.reset}`}>
        {error?.data?.error === 'Your token has expired. Please attempt to reset your password again.' || message === 'Password changed successfully. Please login with your new password.'
          ? <Link to={'/login'}>
            <Button>
              Retour à la page de connexion
            </Button>
          </Link>
          : <Button
            handleClick={handleSubmit(onSubmit)}
            isLoading={isLoading}>
            Valider
          </Button>
        }
        </div>
        <p className={styles.message}>
          {error?.data?.error === 'Your token has expired. Please attempt to reset your password again.'
            && <span className={styles.error}>
              Votre jeton a expiré. Veuillez réessayer de réinitialiser votre mot de passe.
            </span>
          }
          {error?.data?.error === 'You must enter a password strong enough.'
            && <span className={styles.error}>
              Vous devez saisir un mot de passe suffisamment fort.
            </span>
          }
          {message === 'Password changed successfully. Please login with your new password.'
            && <span>
              Le mot de passe a été changé avec succès. <br/>
              Veuillez vous connecter avec votre nouveau mot de passe.
            </span>
          }
        </p>
      </form>
    </div>
  );
}
