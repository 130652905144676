import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { loginAction, forgotPasswordAction, getUserAuthAction } from '../../actions/authActions';
import { getStructuresAction } from '../../actions/structuresActions';

import logo from '../../assets/images/logo_white.png';

import InputEmail from '../../components/lib/InputEmail';
import InputText from '../../components/lib/InputText';
import ErrorField from '../../components/lib/ErrorField';
import Button from '../../components/lib/Button';
import styles from './Auth.module.scss';

export default function Login() {
  const dispatch = useDispatch();
  const { userAuth, error, message } = useSelector((state) => state.auth);
  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: {
      errors,
    },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const clearError = useCallback(() => {
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  }, [dispatch]);

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
    getStructuresAction(dispatch);
  }, [dispatch]);

  function onSubmit(values) {
    setIsLoading(true);
    clearError();
    loginAction(dispatch, values);
  }

  function submitForgot() {
    clearError();
    if (!getValues('email')) {
      return setError('email', {
        type: 'manual',
        message: "L'email est requis",
      });
    }
    return forgotPasswordAction(dispatch, { email: getValues('email') });
  }

  useEffect(() => {
    clearError();
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [error, message]);

  useEffect(() => {
    if (userAuth) {
      getUser();
    }
  }, [userAuth]);

  return (
    <div className={styles.container}>
      <form>
        <div className={styles.logo}>
          <img
            src={logo}
            alt="logo"
          />
        </div>
        <InputEmail
          name='email'
          label='Adresse email'
          control={control}
          className={'login'}
          required
          placeholder={'email'}
        />
        <ErrorField message={errors.email?.message}/>
        <InputText
          name='password'
          label='Mot de passe'
          control={control}
          className={'login'}
          rules={{ required: 'le mot de passe est requis' }}
          password
          placeholder={'mot de passe'}
          />
        <ErrorField message={errors.password?.message}/>
        <div className={styles['btn-container']}>
          <button
            type="button"
            onClick={() => submitForgot()}
            className={styles.forgot}>
            Mot de passe oublié ?
          </button>
          <Button
            handleClick={handleSubmit(onSubmit)}
            isLoading={isLoading}>
            Valider
          </Button>
        </div>
        <p className={styles.message}>
          {getValues('email') && getValues('password') && error === 401
            && <span className={styles.error}>
              L'email ou le mot de passe<br/>est incorrect.
            </span>
          }
          {error?.data?.error === 'Your request could not be processed as entered. Please try again.'
            && <span className={styles.error}>
              Votre email est incorrect
            </span>
          }
          {message === 'Please check your email for the link to reset your password.'
            && <span>
              Veuillez vérifier votre courrier électronique<br/>
              pour réinitialiser votre mot de passe.
            </span>
          }
        </p>
      </form>
    </div>
  );
}
