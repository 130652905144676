import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFrequentationAction } from '../../../actions/visualisationActions';
import FrequentationRatio from '../../../components/partials/Charts/FrequentationRatio/FrequentationRatio';
import GroupedBarChart from '../../../components/partials/Charts/GroupedBarChart/GroupedBarChart';
import SelectGraph from '../../../components/partials/SelectGraph';

import {
  colors, pieColors,
} from '../../../constants/constants';

import styles from './Frequentation.module.scss';

export default function Frequentation() {
  const { frequentation, filters } = useSelector((state) => state.visualisation);
  const dispatch = useDispatch();

  const getData = useCallback(() => {
    getFrequentationAction(dispatch, filters);
  }, [dispatch, filters]);

  useEffect(() => {
    if (filters.structures.length > 0) {
      getData();
    }
  }, [filters]);

  function getTotal() {
    const global = {
      'Période de comparaison': 0,
      'Année N-2': 0,
      Prévisionnel: 0,
      Réel: 0,
      activity: '',
    };
    frequentation?.byType.forEach((t) => {
      if (t.activity === 'Abonnés') return;
      global['Période de comparaison'] += t['Période de comparaison'];
      global['Année N-2'] += t['Année N-2'];
      global['Prévisionnel'] += t['Prévisionnel'];
      global['Réel'] += t['Réel'];
    });
    return global;
  }

  return (
    <div className={`page-content visualisation ${styles.container}`}>
      {frequentation
      && <>
        <div className={styles.block}>
          {frequentation?.byType
            && <SelectGraph
            data={frequentation?.byType}
            colors={colors}
            unit={'K'}
            legend
            total={getTotal().Réel.toFixed(0)}
            indexBy='activity'
            keys={['Réel', 'Prévisionnel', 'Période de comparaison']}
            label='FRÉQUENTATION PAR FAMILLES'
            // period={period}
            />
          }
        </div>
        <div className={styles.row}>
          <div className={`${styles.col}`}>
            <GroupedBarChart
              data={[getTotal()]}
              colors={colors}
              unit={'K'}
              padding={0.2}
              margin={{
                top: 20, right: 10, bottom: 10, left: 70,
              }}
              enableLabel={false}
              // period={period}
              indexBy='activity'
              keys={['Réel', 'Prévisionnel', 'Période de comparaison']}
              label='FRÉQUENTATION GLOBALE'
              />
          </div>
          <div className={`${styles.col} ${styles.ratio}`}>
            {frequentation?.byOrigin
              && <FrequentationRatio
                colors={pieColors}
                data={frequentation?.byOrigin}
                label={'RATIO DE PROVENANCE'}
                />
            }
          </div>
        </div>
      </>
      }
    </div>
  );
}
