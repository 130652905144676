import React from 'react';
import { Link } from 'react-router-dom';
import { BsGearFill } from 'react-icons/bs';
import styles from './StructureRow.module.scss';

export default function StructureRow({ structure }) {
  const profile = structure?.administrator?.profile;
  return (
    <Link
      to={`/admin/structure/${structure._id}`}
      className={styles.container}>
      <p >{structure.name}</p>
      <p className={styles.admin}>
        {profile?.lastName?.toUpperCase()} {profile?.firstName}
      </p>
      <div className={styles.icon}>
        <BsGearFill size={28} />
      </div>
    </Link>
  );
}
