import React, { useEffect, useCallback } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAuthAction } from '../actions/authActions';
import { getStructuresAction } from '../actions/structuresActions';

import setVhUnit from '../utils/utils';

import Login from './Auth/Login';
import ResetPassword from '../pages/Auth/ResetPassword';
import Toaster from '../components/lib/Toaster/Toaster';
import Index from '../pages/Auth/SwitchIndex';
import AdminRouter from './Admin/AdminRouter';
import VisualisationRouter from './Visualisation/VisualisationRouter';
import FormsRouter from './Forms/FormsRouter';
import {
  SET_ROLE, USER_AUTH, RESET_CONFIG,
} from '../actions/types';
import ProfileRouter from './Profile/ProfileRouter';

export default function GlobalRouter() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    error, toast, userAuth, role,
  } = useSelector((state) => state.auth);
  const { structuresList } = useSelector((state) => state.structures);
  const token = localStorage.getItem('token');

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  }, [dispatch]);

  const getStructures = useCallback(() => {
    getStructuresAction(dispatch);
  }, [dispatch]);

  function logout() {
    history.push('/login');
    localStorage.removeItem('token');
    dispatch({
      type: RESET_CONFIG,
    });
    dispatch({
      type: SET_ROLE,
      payload: null,
    });
    dispatch({
      type: USER_AUTH,
      payload: null,
    });
  }

  useEffect(() => {
    if (token) {
      getUser();
      getStructures();
    }
    setVhUnit();
  }, []);

  useEffect(() => {
    if (error === 401) {
      logout();
    }
  }, [error]);

  useEffect(() => {
    if (!role && structuresList && userAuth) {
      if (userAuth?.role === 'Admin') {
        history.push('/admin');
        dispatch({
          type: SET_ROLE,
          payload: 'Admin',
        });
      } else {
        const userRole = structuresList[0]?.users?.find((u) => u.user._id === userAuth._id).role;
        history.push('/visualisation/synthese');
        dispatch({
          type: SET_ROLE,
          payload: userRole,
        });
      }
    }
  }, [structuresList, userAuth, role, history]);

  return (
    <>
      <Switch>

        <Route exact path='/' render={
            () => <Index />
          }/>

        <Route path='/login' render={
          () => <Login />
        }/>

        <Route exact path='/reset-password/:token' render={
          () => <ResetPassword/>
        }/>

        <Route path='/profile' render={
          () => <ProfileRouter
            logout={logout}
          />
        }/>

        <Route path='/admin/:page?/:structure?' render={
          () => <AdminRouter
          />
        }/>

        <Route exact path='/visualisation/:page?/:structure?' render={
          () => <VisualisationRouter
          />
        }/>

        <Route exact path='/forms/:page?/:structure?' render={
          () => <FormsRouter
          />
        }/>

      </Switch>
      <Toaster toast={toast} dispatch={dispatch}/>
    </>
  );
}
