export default function setVhUnit() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

export function calcPercentage(a, b) {
  const decreaseValue = (1 - (a / b)) * 100;
  return decreaseValue;
}

export const hexToRgba = (hex, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${ r }, ${ g }, ${ b }, ${ alpha })`;
};

export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
}
