import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import FormNav from '../../components/partials/FormNav/FormNav';
import Header from '../../components/partials/Header/Header';
import Consumption from './Consumption/Consumption';
import Expence from './Expense/Expense';
import Payroll from './Payroll/Payroll';
import Sales from './Sales/Sales';
import Frequentation from './Frequentation/Frequentation';
import Filling from './Filling/Filling';

export default function FormsRouter() {
  const location = useLocation();
  const transitions = useTransition(location, {
    key: location.pathname,
    from: { opacity: 1, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(100%, 0,0)' },
  });

  return (
    <div className="page-container">
      <Header/>
      <FormNav/>
        {transitions((props, item, t, key) => (
          <animated.div key={key} style={props}>
            <Switch location={item}>

              <Route exact path={'/forms/:structure'} render={
                () => <Sales />
              }/>

              <Route exact path={'/forms/frequentation/:structure'} render={
                () => <Frequentation />
              }/>

              <Route exact path={'/forms/filling/:structure'} render={
                () => <Filling />
              }/>

              <Route exact path={'/forms/consumption/:structure'} render={
                () => <Consumption />
              }/>

              <Route exact path={'/forms/payroll/:structure'} render={
                () => <Payroll />
              }/>

              <Route exact path={'/forms/expense/:structure'} render={
                () => <Expence />
              }/>

            </Switch>
          </animated.div>
        ))}
    </div>
  );
}
