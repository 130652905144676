import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import Header from '../../components/partials/Header/Header';
import AdminPage from './AdminPage/AdminPage';
import StructureListPage from './StructureListPage/StructureListPage';
import StructurePage from './StructurePage/StructurePage';

export default function AdminRouter() {
  const location = useLocation();
  const transitions = useTransition(location, {
    from: { opacity: 1, transform: 'translate3d(-120%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(120%, 0,0)' },
  });

  return (
    <div className="page-container">
      <Header
        />
      {transitions((props, item, key) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>

            <Route exact path={'/admin'} render={
              () => <StructureListPage />
            }/>

            <Route exact path={'/admin/users'} render={
              () => <AdminPage />
            }/>

            <Route exact path={'/admin/structure/:id'} render={
              () => <StructurePage />
            }/>

          </Switch>
        </animated.div>
      ))}
    </div>
  );
}
