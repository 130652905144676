import React, { useEffect, useState } from 'react';

import styles from './CheckActivitiesList.module.scss';

export default function CheckActivitiesList({
  list,
  activeList,
  label,
  handleChange,
}) {
  const [selectedItems, setSelectedItems] = useState(activeList);

  function handleClick(item) {
    const activity = {
      type: label,
      subType: item,
    };
    const selected = [...selectedItems];
    if (selected?.find((a) => a.subType === item && a.type === label)) {
      return setSelectedItems(selected.filter((s) => s.subType !== item));
    }
    return setSelectedItems([...selected, activity]);
  }

  useEffect(() => {
    setSelectedItems(activeList);
  }, [activeList]);

  useEffect(() => {
    handleChange(selectedItems);
  }, [selectedItems]);

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <div className={styles.checklist}>
      {list?.map((item) => {
        const isActive = selectedItems?.find((a) => a.subType === item && a.type === label);
        return (
          <div key={item}
            className={styles.checkbox}
            onClick={() => handleClick(item)}>
            <input type="checkbox" checked={!!isActive} readOnly name={item} id={item} />
            <label htmlFor={item} aria-describedby="label">{item}</label>
          </div>
        );
      })}
      </div>
    </div>
  );
}
