import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import Header from '../../components/partials/Header/Header';
import DataNav from '../../components/partials/DataNav/DataNav';
import RateFilling from './ RateFilling/RateFilling';
import Synthesis from './Synthesis/Synthesis';
import Ca from './Ca/Ca';
import Frequentation from './Frequentation/Frequentation';
import Expense from './Expense/Expense';
import Consumption from './Consumption/Consumption';
import Documents from './Documents/Documents';

export default function VisualisationRouter() {
  const location = useLocation();

  const transitions = useTransition(location, {
    key: location.pathname,
    from: { opacity: 1, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(100%, 0,0)' },
  });

  return (
    <div className="page-container">
      <Header />
      <DataNav />
      {transitions((props, item, t, key) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>

            <Route exact path={'/visualisation/synthese'} render={
              () => <Synthesis />
            }/>

            <Route exact path={'/visualisation/frequentation'} render={
              () => <Frequentation />
            }/>

            <Route exact path={'/visualisation/ca'} render={
              () => <Ca />
            }/>

            <Route exact path={'/visualisation/expense'} render={
              () => <Expense />
            }/>

            <Route exact path={'/visualisation/filling'} render={
              () => <RateFilling />
            }/>

            <Route exact path={'/visualisation/consumption'} render={
              () => <Consumption />
            }/>

            <Route exact path={'/visualisation/documents'} render={
              () => <Documents />
            }/>

          </Switch>
        </animated.div>
      ))}
    </div>
  );
}
