import React from 'react';
import { useSelector } from 'react-redux';
import Notifications from '../../../components/partials/Notifications/Notifications';
import { rolesOptions } from '../../../constants/constants';
import logoutImg from './images/logout.svg';
import profileImg from './images/profile.svg';
import styles from './Profile.module.scss';

export default function Profile({ logout }) {
  const { userAuth } = useSelector((state) => state.auth);
  const role = rolesOptions.find((r) => r.value === userAuth?.role)?.label;
  return (
    <div className={`page-content profile ${styles.container}`}>
      <div className={styles.col}>
        <div className={styles.profile}>
          <h2>{userAuth?.profile?.firstName} {userAuth?.profile?.lastName}</h2>
          <p>{role}</p>
          <button
            className={styles.logout}
            onClick={() => logout()}
          >
            <img src={logoutImg} alt="Déconnexion" />
            <p>Déconnexion</p>
          </button>
        </div>
        <img src={profileImg} className={styles.illu} alt="Déconnexion" />
      </div>
      <div className={styles.col}>
        <Notifications />
      </div>
    </div>
  );
}
