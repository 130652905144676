import { createStore, combineReducers } from 'redux';

import authReducer from '../reducers/authReducer';
import structuresReducer from '../reducers/structuresReducer';
import visualisationReducer from '../reducers/visualisationReducer';
import adminReducer from '../reducers/adminReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  structures: structuresReducer,
  visualisation: visualisationReducer,
  admin: adminReducer,
});

export default createStore(rootReducer);
