import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
// import { RiCloseLine } from 'react-icons/ri';

import { SET_VISUALISATION_FILTERS } from '../../../actions/types';
import { getStructuresAction } from '../../../actions/structuresActions';

import CustomSelect from '../../lib/Select';

import synth from '../../../assets/images/icons/synth.svg';
import synthActive from '../../../assets/images/icons/synth-active.svg';
import freq from '../../../assets/images/icons/freq.svg';
import freqActive from '../../../assets/images/icons/freq-active.svg';
import ca from '../../../assets/images/icons/CA.svg';
import caActive from '../../../assets/images/icons/CA-active.svg';
import depenses from '../../../assets/images/icons/depenses.svg';
import depensesActive from '../../../assets/images/icons/depenses-active.svg';
import doc from '../../../assets/images/icons/doc.svg';
import docActive from '../../../assets/images/icons/doc-active.svg';
import energie from '../../../assets/images/icons/energie.svg';
import energieActive from '../../../assets/images/icons/energie-active.svg';
// import remplissage from '../../../assets/images/icons/remplissage.svg';
// import remplissageActive from '../../../assets/images/icons/remplissage-active.svg';

import styles from './DataNav.module.scss';

export default function DataNav() {
  const { structuresList } = useSelector((state) => state.structures);
  const { filters } = useSelector((state) => state.visualisation);
  const { structures } = filters;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const structuresOptions = structuresList?.map((s) => ({ label: s.name, value: s._id }));

  // const isAllStructures = structuresList?.length === structures?.length;

  const getStructures = useCallback(() => {
    getStructuresAction(dispatch);
  }, [dispatch]);

  function handleChangeFilters(obj) {
    dispatch({
      type: SET_VISUALISATION_FILTERS,
      payload: { ...filters, ...obj },
    });
  }

  function handleChangeStructures(value) {
    localStorage.setItem('structure', value);
    console.log(value);
    return handleChangeFilters({
      structures: [value],
    });
  }

  useEffect(() => {
    getStructures();
  }, []);

  useEffect(() => {
    if (structures.length === 0 && structuresList) {
      const defaultValue = localStorage.getItem('structure') || structuresList[0];
      handleChangeFilters({
        structures: [defaultValue],
      });
    }
  }, [structuresList]);

  return (
    <div className={`${styles.container} ${structuresList ? styles.active : ''}`}>
      <div className={styles.select}>
        {structuresOptions && filters.structures[0] && <CustomSelect
          options={structuresOptions}
          value={filters.structures[0]}
          isMulti={false}
          handleChange={(value) => handleChangeStructures(value)}
          />
        }
      </div>
      {/* {!isAllStructures
        && <div className={styles.structures}>
          {structuresList?.filter((s) => structures?.find((id) => id === s._id)).map((s) => <div
            key={s._id}
            className={styles.structure}
          >
            <p>{s.name}</p>
             <button
              onClick={() => handleChangeStructures(s._id)}
              >
              <RiCloseLine size={15} />
            </button>
          </div>)}
        </div>
      } */}
      <nav>
        <NavLink
          to={'/visualisation/synthese'}
          activeClassName={styles['active-link']}>
          <img src={pathname.includes('synthese') ? synthActive : synth } alt="Synthèse" />
          Synthèse
        </NavLink>
        <NavLink
          to={'/visualisation/frequentation'}
          activeClassName={styles['active-link']}>
          <img src={pathname.includes('frequentation') ? freqActive : freq } alt="fréquentation" />
          Fréquentation
        </NavLink>
        <NavLink
          to={'/visualisation/ca'}
          activeClassName={styles['active-link']}
          >
          <img src={pathname.includes('ca') ? caActive : ca } alt="ca" />
          CA
        </NavLink>
        <NavLink
          to={'/visualisation/expense'}
          activeClassName={styles['active-link']}
          >
          <img src={pathname.includes('expense') ? depensesActive : depenses } alt="dépenses" />
          Dépenses
        </NavLink>
        {/* <NavLink
          to={'/visualisation/filling'}
          activeClassName={styles['active-link']}
          >
          <img
            src={pathname.includes('filling')
              ? remplissageActive
              : remplissage
            }
            alt="remplissage"
          />
          Taux de remplissage
        </NavLink> */}
        <NavLink
          to={'/visualisation/consumption'}
          activeClassName={styles['active-link']}
          >
          <img src={pathname.includes('consumption') ? energieActive : energie } alt="energie" />
          Consommation
          énergétique
        </NavLink>
        <NavLink
          to={'/visualisation/documents'}
          activeClassName={styles['active-link']}
          >
          <img src={pathname.includes('documents') ? docActive : doc } alt="documents" />
          Partage de
          documents
        </NavLink>
      </nav>
    </div>
  );
}
