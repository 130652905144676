import React from 'react';
import { useDispatch } from 'react-redux';
import { AiFillDelete } from 'react-icons/ai';
import { format } from 'date-fns';

import styles from './ExpenseRow.module.scss';
import { deleteExpenseAction } from '../../../actions/formsActions';
import { numberWithSpaces } from '../../../utils/utils';

export default function ExpenseRow({
  expense,
}) {
  const dispatch = useDispatch();
  return (
    <div className={styles.container} >
      <div className={`${styles.col} ${styles.name}`}>
        <p>{expense.description}</p>
      </div>
      <div className={styles.col}>
        <p>{expense.date && format(new Date(expense.date), 'dd/MM/yyyy')}</p>
      </div>
      <div className={`${styles.col} ${styles.amount}`}>
        <p>{expense.amount && numberWithSpaces(expense.amount)} €</p>
        <button
          onClick={() => deleteExpenseAction(dispatch, expense._id)}
          >
          <AiFillDelete size={18}/>
        </button>
      </div>

    </div>
  );
}
