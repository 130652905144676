import {
  ERROR_STRUCTURE,
  MESSAGE_STRUCTURE,
  GET_STRUCTURES,
  SET_CONFIG,
  RESET_CONFIG,
  GET_STRUCTURE,
  POST_STRUCTURE,
  GET_USERS,
  GET_FILES,
  GET_LISTS,
  POST_FILE,
  DELETE_FILE,
  POST_STRUCTURE_DATA,
  POST_USER,
  PUT_CONSUMPTION,
  PUT_SALE,
  PUT_FREQUENTATION,
  PUT_PAYROLL,
  PUT_FILLING,
  POST_EXPENSE,
  DELETE_EXPENSE,
  EXPORT_IS_LOADING,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  structuresList: null,
  structure: null,
  user: null,
  data: null,
  provisionalData: null,
  usersList: null,
  files: [],
  file: null,
  loaders: {
    export: false,
  },
  config: {
    year: new Date().getFullYear(),
    isProvisional: false,
    structure: null,
    type: null,
  },
  lists: {
    energy: ['Électricité', 'Gaz', 'Bois'],
    activities: ['Futures mamans', 'O’Gym', 'O’dynamic', 'O’bike', 'O’hiit', 'O’crossfit', 'O’dos'],
    documents: ['Inventaire', 'Contrôles réglementaires', 'Analyses ars', 'suivi des doléances', 'Devis', 'Supports réunions mensuelles', 'Planification', 'Divers'],
  },
};

export default function structureReducer(state = initialState, action) {
  const data = state.data ? [...state.data] : [];
  const index = data?.findIndex((d) => d._id === action.payload?._id);
  switch (action.type) {
    case EXPORT_IS_LOADING:
      return { ...state, loaders: { ...state.loaders, export: action.payload } };
    case GET_LISTS:
      return { ...state, lists: { ...state.lists, ...action.payload } };
    case MESSAGE_STRUCTURE:
      return { ...state, message: action.payload };
    case POST_FILE:
      return { ...state, file: action.payload };
    case DELETE_FILE:
      return { ...state, files: state.files.filter((f) => f._id !== action.payload), file: null };
    case GET_FILES:
      return { ...state, files: action.payload };
    case GET_USERS:
      return { ...state, usersList: action.payload };
    case GET_STRUCTURES:
      return { ...state, structuresList: action.payload };
    case POST_STRUCTURE:
      return { ...state, structuresList: [...state.structuresList, action.payload] };
    case POST_STRUCTURE_DATA:
      return { ...state, data: action.payload };
    case POST_EXPENSE:
      return { ...state, data: [...state.data, action.payload] };
    case DELETE_EXPENSE:
      return { ...state, data: state.data.filter((d) => d._id !== action.payload) };
    case PUT_SALE:
      if (index) {
        data[index] = action.payload;
      }
      return { ...state, data };
    case PUT_FILLING:
      if (index) {
        data[index] = action.payload;
      }
      return { ...state, data };
    case PUT_PAYROLL:
      if (index) {
        data[index] = action.payload;
      }
      return { ...state, data };
    case PUT_CONSUMPTION:
      if (index) {
        data[index] = action.payload;
      }
      return { ...state, data };
    case PUT_FREQUENTATION:
      if (index) {
        data[index] = action.payload;
      }
      return { ...state, data };
    case RESET_CONFIG:
      return { ...state, config: initialState.config };
    case SET_CONFIG:
      return { ...state, config: { ...action.payload } };
    case GET_STRUCTURE:
      return { ...state, structure: action.payload, user: null };
    case ERROR_STRUCTURE:
      return { ...state, error: action.payload };
    case POST_USER:
      return { ...state, user: action.payload };
    default:
      return { ...state };
  }
}
