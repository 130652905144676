export const months = {
  april: 'Avril',
  august: 'Août',
  december: 'Décembre',
  february: 'Février',
  january: 'Janvier',
  july: 'Juillet',
  june: 'Juin',
  march: 'Mars',
  may: 'Mai',
  november: 'Novembre',
  october: 'Octobre',
  september: 'Septembre',
};

// export const periodsOptions = [
//   {
//     label: 'Année en cours',
//     value: 'year',
//   },
//   {
//     label: 'Mois en cours',
//     value: 'currentMonth',
//   },
//   {
//     label: 'Mois écoulé',
//     value: 'lastMonth',
//   },
// ];

export const monthsArray = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const year = new Date().getFullYear();
export const yearsOptions = [
  year,
  year - 1,
  year - 2,
].map((y) => y.toString());

const monthsOptions = [];

yearsOptions.forEach((y) => {
  monthsArray.forEach((m) => {
    monthsOptions.push({
      month: m,
      year: y,
      label: `${months[m]} ${y}`,
    });
  });
});

export const periodsOptions = {
  y1: yearsOptions.map((d) => ({ value: { y1: d, m0: '', m1: '' }, label: d })),
  y0: yearsOptions.map((d) => ({ value: { y0: d, m0: '', m1: '' }, label: d })),
  m1: monthsOptions.map((d) => ({ value: { y1: d.year, m1: d.month }, label: d.label })),
  m0: monthsOptions.map((d) => ({ value: { y0: d.year, m0: d.month }, label: d.label })),
};

export const rightsOptions = [
  {
    value: 'Member',
    label: 'Membre',
  },
  {
    value: 'Owner',
    label: 'Gestionnaire',
  },
  // {
  //   value: 'Admin',
  //   label: 'Administrateur',
  // },
];

export const rolesOptions = [
  {
    value: 'Member',
    label: 'Membre',
  },
  {
    value: 'Owner',
    label: 'Gestionnaire',
  },
  {
    value: 'Admin',
    label: 'Administrateur',
  },
];

export const defaultBarData = [
  {
    country: 'AD',
    'hot dog': 98,
    'hot dogColor': 'hsl(344, 70%, 50%)',
    burger: 13,
    burgerColor: 'hsl(182, 70%, 50%)',
    sandwich: 68,
    sandwichColor: 'hsl(277, 70%, 50%)',
    kebab: 95,
    kebabColor: 'hsl(43, 70%, 50%)',
    fries: 131,
    friesColor: 'hsl(29, 70%, 50%)',
    donut: 197,
    donutColor: 'hsl(180, 70%, 50%)',
  },
  {
    country: 'AE',
    'hot dog': 101,
    'hot dogColor': 'hsl(334, 70%, 50%)',
    burger: 37,
    burgerColor: 'hsl(87, 70%, 50%)',
    sandwich: 163,
    sandwichColor: 'hsl(188, 70%, 50%)',
    kebab: 30,
    kebabColor: 'hsl(258, 70%, 50%)',
    fries: 112,
    friesColor: 'hsl(330, 70%, 50%)',
    donut: 169,
    donutColor: 'hsl(318, 70%, 50%)',
  },
  {
    country: 'AF',
    'hot dog': 196,
    'hot dogColor': 'hsl(146, 70%, 50%)',
    burger: 6,
    burgerColor: 'hsl(193, 70%, 50%)',
    sandwich: 129,
    sandwichColor: 'hsl(338, 70%, 50%)',
    kebab: 107,
    kebabColor: 'hsl(324, 70%, 50%)',
    fries: 34,
    friesColor: 'hsl(115, 70%, 50%)',
    donut: 20,
    donutColor: 'hsl(209, 70%, 50%)',
  },
  {
    country: 'AG',
    'hot dog': 127,
    'hot dogColor': 'hsl(320, 70%, 50%)',
    burger: 179,
    burgerColor: 'hsl(83, 70%, 50%)',
    sandwich: 27,
    sandwichColor: 'hsl(6, 70%, 50%)',
    kebab: 86,
    kebabColor: 'hsl(179, 70%, 50%)',
    fries: 91,
    friesColor: 'hsl(344, 70%, 50%)',
    donut: 17,
    donutColor: 'hsl(24, 70%, 50%)',
  },
  {
    country: 'AI',
    'hot dog': 177,
    'hot dogColor': 'hsl(63, 70%, 50%)',
    burger: 34,
    burgerColor: 'hsl(75, 70%, 50%)',
    sandwich: 105,
    sandwichColor: 'hsl(210, 70%, 50%)',
    kebab: 41,
    kebabColor: 'hsl(340, 70%, 50%)',
    fries: 155,
    friesColor: 'hsl(114, 70%, 50%)',
    donut: 88,
    donutColor: 'hsl(172, 70%, 50%)',
  },
  {
    country: 'AL',
    'hot dog': 139,
    'hot dogColor': 'hsl(309, 70%, 50%)',
    burger: 103,
    burgerColor: 'hsl(189, 70%, 50%)',
    sandwich: 178,
    sandwichColor: 'hsl(133, 70%, 50%)',
    kebab: 84,
    kebabColor: 'hsl(291, 70%, 50%)',
    fries: 104,
    friesColor: 'hsl(356, 70%, 50%)',
    donut: 20,
    donutColor: 'hsl(77, 70%, 50%)',
  },
  {
    country: 'AM',
    'hot dog': 133,
    'hot dogColor': 'hsl(120, 70%, 50%)',
    burger: 132,
    burgerColor: 'hsl(132, 70%, 50%)',
    sandwich: 55,
    sandwichColor: 'hsl(33, 70%, 50%)',
    kebab: 54,
    kebabColor: 'hsl(144, 70%, 50%)',
    fries: 182,
    friesColor: 'hsl(177, 70%, 50%)',
    donut: 25,
    donutColor: 'hsl(356, 70%, 50%)',
  },
];

const max = 100;
function getRandomInt() {
  return Math.floor(Math.random() * max);
}

export const colors = {
  Réel: '#97E7F4',
  Prévisionnel: '#ECECF0',
  'Période de comparaison': '#6DA4C6',
};

export const barData = [
  {
    activity: 'Futures mamans',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
  {
    activity: 'O’Gym',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
  {
    activity: 'O’Dynamic',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
  {
    activity: 'O’Bike',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
  {
    activity: 'O’Boxe',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
  {
    activity: 'O’Hiit',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
  {
    activity: 'O’Cross fit',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
  {
    activity: 'O’Zen',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
  {
    activity: 'O’Cross fit 1',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
  {
    activity: 'O’Zen 1',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
  {
    activity: 'O’Cross fit 2',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
  {
    activity: 'O’Zen 2',
    'Année N-1': getRandomInt(),
    'Année N-2': getRandomInt(),
    Prévisionnel: getRandomInt(),
    Réel: getRandomInt(),
  },
];

export const defaultLineData = [
  {
    id: 'japan',
    color: 'hsl(203, 70%, 50%)',
    data: [
      {
        x: 'Janvier',
        y: 49,
      },
      {
        x: 'Février',
        y: 23,
      },
      {
        x: 'Mars',
        y: 242,
      },
      {
        x: 'Avril',
        y: 110,
      },
      {
        x: 'Mai',
        y: 138,
      },
      {
        x: 'Juin',
        y: 265,
      },
      {
        x: 'Juillet',
        y: 212,
      },
      {
        x: 'Août',
        y: 74,
      },
      {
        x: 'Septembre',
        y: 10,
      },
      {
        x: 'Octobre',
        y: 99,
      },
      {
        x: 'Novembre',
        y: 233,
      },
      {
        x: 'Décembre',
        y: 285,
      },
    ],
  },
  {
    id: 'france',
    color: 'hsl(68, 70%, 50%)',
    data: [
      {
        x: 'Janvier',
        y: 91,
      },
      {
        x: 'Février',
        y: 223,
      },
      {
        x: 'Mars',
        y: 236,
      },
      {
        x: 'Avril',
        y: 97,
      },
      {
        x: 'Mai',
        y: 262,
      },
      {
        x: 'Juin',
        y: 19,
      },
      {
        x: 'Juillet',
        y: 226,
      },
      {
        x: 'Août',
        y: 64,
      },
      {
        x: 'Septembre',
        y: 259,
      },
      {
        x: 'Octobre',
        y: 293,
      },
      {
        x: 'Novembre',
        y: 59,
      },
      {
        x: 'Décembre',
        y: 210,
      },
    ],
  },
  {
    id: 'us',
    color: 'hsl(154, 70%, 50%)',
    data: [
      {
        x: 'Janvier',
        y: 283,
      },
      {
        x: 'Février',
        y: 69,
      },
      {
        x: 'Mars',
        y: 94,
      },
      {
        x: 'Avril',
        y: 168,
      },
      {
        x: 'Mai',
        y: 283,
      },
      {
        x: 'Juin',
        y: 271,
      },
      {
        x: 'Juillet',
        y: 278,
      },
      {
        x: 'Août',
        y: 250,
      },
      {
        x: 'Septembre',
        y: 171,
      },
      {
        x: 'Octobre',
        y: 24,
      },
      {
        x: 'Novembre',
        y: 13,
      },
      {
        x: 'Décembre',
        y: 3,
      },
    ],
  },
  {
    id: 'germany',
    color: 'hsl(138, 70%, 50%)',
    data: [
      {
        x: 'Janvier',
        y: 267,
      },
      {
        x: 'Février',
        y: 195,
      },
      {
        x: 'Mars',
        y: 196,
      },
      {
        x: 'Avril',
        y: 205,
      },
      {
        x: 'Mai',
        y: 283,
      },
      {
        x: 'Juin',
        y: 226,
      },
      {
        x: 'Juillet',
        y: 163,
      },
      {
        x: 'Août',
        y: 55,
      },
      {
        x: 'Septembre',
        y: 73,
      },
      {
        x: 'Octobre',
        y: 126,
      },
      {
        x: 'Novembre',
        y: 46,
      },
      {
        x: 'Décembre',
        y: 171,
      },
    ],
  },
  {
    id: 'norway',
    color: 'hsl(315, 70%, 50%)',
    data: [
      {
        x: 'Janvier',
        y: 142,
      },
      {
        x: 'Février',
        y: 79,
      },
      {
        x: 'Mars',
        y: 239,
      },
      {
        x: 'Avril',
        y: 251,
      },
      {
        x: 'Mai',
        y: 88,
      },
      {
        x: 'Juin',
        y: 67,
      },
      {
        x: 'Juillet',
        y: 179,
      },
      {
        x: 'Août',
        y: 247,
      },
      {
        x: 'Septembre',
        y: 51,
      },
      {
        x: 'Octobre',
        y: 223,
      },
      {
        x: 'Novembre',
        y: 260,
      },
      {
        x: 'Décembre',
        y: 138,
      },
    ],
  },
];

export const lineData = [
  {
    id: 'Année N-1',
    data: [
      {
        x: 'Janvier',
        y: 49,
      },
      {
        x: 'Février',
        y: 23,
      },
      {
        x: 'Mars',
        y: 242,
      },
      {
        x: 'Avril',
        y: 110,
      },
      {
        x: 'Mai',
        y: 138,
      },
      {
        x: 'Juin',
        y: 265,
      },
      {
        x: 'Juillet',
        y: 212,
      },
      {
        x: 'Août',
        y: 74,
      },
      {
        x: 'Septembre',
        y: 10,
      },
      {
        x: 'Octobre',
        y: 99,
      },
      {
        x: 'Novembre',
        y: 233,
      },
      {
        x: 'Décembre',
        y: 285,
      },
    ],
  },
  {
    id: 'Année N-2',
    data: [
      {
        x: 'Janvier',
        y: 91,
      },
      {
        x: 'Février',
        y: 223,
      },
      {
        x: 'Mars',
        y: 236,
      },
      {
        x: 'Avril',
        y: 97,
      },
      {
        x: 'Mai',
        y: 262,
      },
      {
        x: 'Juin',
        y: 19,
      },
      {
        x: 'Juillet',
        y: 226,
      },
      {
        x: 'Août',
        y: 64,
      },
      {
        x: 'Septembre',
        y: 259,
      },
      {
        x: 'Octobre',
        y: 293,
      },
      {
        x: 'Novembre',
        y: 59,
      },
      {
        x: 'Décembre',
        y: 210,
      },
    ],
  },
  {
    id: 'Réel',
    data: [
      {
        x: 'Janvier',
        y: 283,
      },
      {
        x: 'Février',
        y: 69,
      },
      {
        x: 'Mars',
        y: 94,
      },
      {
        x: 'Avril',
        y: 168,
      },
      {
        x: 'Mai',
        y: 283,
      },
      {
        x: 'Juin',
        y: 271,
      },
      {
        x: 'Juillet',
        y: 278,
      },
      {
        x: 'Août',
        y: 250,
      },
      {
        x: 'Septembre',
        y: 171,
      },
      {
        x: 'Octobre',
        y: 24,
      },
      {
        x: 'Novembre',
        y: 13,
      },
      {
        x: 'Décembre',
        y: 3,
      },
    ],
  },
  {
    id: 'Prévisionnel',
    data: [
      {
        x: 'Janvier',
        y: 267,
      },
      {
        x: 'Février',
        y: 195,
      },
      {
        x: 'Mars',
        y: 196,
      },
      {
        x: 'Avril',
        y: 205,
      },
      {
        x: 'Mai',
        y: 283,
      },
      {
        x: 'Juin',
        y: 226,
      },
      {
        x: 'Juillet',
        y: 163,
      },
      {
        x: 'Août',
        y: 55,
      },
      {
        x: 'Septembre',
        y: 73,
      },
      {
        x: 'Octobre',
        y: 126,
      },
      {
        x: 'Novembre',
        y: 46,
      },
      {
        x: 'Décembre',
        y: 171,
      },
    ],
  },
];

export const pieDataDefault = [
  {
    id: 'ruby',
    label: 'ruby',
    value: 354,
    color: 'hsl(236, 70%, 50%)',
  },
  {
    id: 'erlang',
    label: 'erlang',
    value: 13,
    color: 'hsl(221, 70%, 50%)',
  },
  {
    id: 'hack',
    label: 'hack',
    value: 283,
    color: 'hsl(269, 70%, 50%)',
  },
  {
    id: 'stylus',
    label: 'stylus',
    value: 542,
    color: 'hsl(307, 70%, 50%)',
  },
  {
    id: 'make',
    label: 'make',
    value: 90,
    color: 'hsl(234, 70%, 50%)',
  },
];

export const pieData = [{
  id: 'territory',
  label: 'Territoire',
  value: getRandomInt(),
},
{
  id: 'outsideTerritory',
  label: 'Hors territoire',
  value: getRandomInt(),
},
];

export const pieColors = {
  outsideTerritory: '#bde5ec',
  territory: '#39a1b3',
};

export const caData = [
  {
    label: 'CA Global',
    reel: 200000,
    provisional: 150000,
    n1: 160000,
    n2: 170000,
    unit: '€',
  },
  {
    label: 'TICKET MOYEN',
    reel: 10.24,
    provisional: 17.45,
    n1: 16,
    n2: 11,
    unit: '€',
  },
];

export const synthesisData = [
  {
    label: 'CA',
    reel: 200000,
    provisional: 150000,
    n1: 160000,
    n2: 170000,
    unit: '€',
  },
  {
    label: 'Fréquentation',
    reel: 500,
    provisional: 430,
    n1: 380,
    n2: 480,
    unit: 'person',
  },
  {
    label: 'nombre d’abonnés',
    reel: 500,
    provisional: 430,
    n1: 380,
    n2: 480,
    unit: 'person',
  },
  {
    label: 'TICKET MOYEN',
    reel: 10.24,
    provisional: 17.45,
    n1: 16,
    n2: 11,
    unit: '€',
  },
];
export const gerData = {
  total: {
    reel: 300000,
    provisional: 468000,
  },
  expenses: [
    {
      _id: '60f6de931c417e6b40dc2edc',
      updatedAt: '2021-07-20T14:32:51.887Z',
      createdAt: '2021-07-20T14:32:51.887Z',
      year: '2020',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      description: 'value',
      date: '2020-01-19T22:00:00.000Z',
      amount: 0,
      __v: 0,
    },
    {
      _id: '60f6e69f1c417e6b40dc2edd',
      updatedAt: '2021-07-20T15:07:11.832Z',
      createdAt: '2021-07-20T15:07:11.832Z',
      year: '2020',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      description: 'value',
      date: '2020-01-19T22:00:00.000Z',
      amount: 0,
      __v: 0,
    },
    {
      _id: '60f6e6a11c417e6b40dc2ede',
      updatedAt: '2021-07-20T15:07:13.072Z',
      createdAt: '2021-07-20T15:07:13.072Z',
      year: '2020',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      description: 'value',
      date: '2020-01-19T22:00:00.000Z',
      amount: 0,
      __v: 0,
    },
    {
      _id: '60f7ed231c417e6b40dc2ee8',
      updatedAt: '2021-07-21T09:47:15.836Z',
      createdAt: '2021-07-21T09:47:15.836Z',
      year: '2020',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: true,
      description: 'value',
      date: '2020-01-19T22:00:00.000Z',
      amount: 0,
      __v: 0,
    },
    {
      _id: '60f7efd71c417e6b40dc2ee9',
      updatedAt: '2021-07-21T09:58:47.186Z',
      createdAt: '2021-07-21T09:58:47.186Z',
      __v: 0,
    },
    {
      _id: '60f7effe1c417e6b40dc2eea',
      updatedAt: '2021-07-21T09:59:26.632Z',
      createdAt: '2021-07-21T09:59:26.632Z',
      description: 'Objet du GER titre sur une ligne',
      amount: 23922,
      date: 'Mon, 28 Jun 2021 22:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f7f0d71c417e6b40dc2eec',
      updatedAt: '2021-07-21T10:03:03.176Z',
      createdAt: '2021-07-21T10:03:03.176Z',
      description: 'Objet du GER titre sur une ligne',
      amount: 23922,
      date: 'Tue, 29 Jun 2021 22:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f7f14b1c417e6b40dc2eed',
      updatedAt: '2021-07-21T10:04:59.473Z',
      createdAt: '2021-07-21T10:04:59.473Z',
      description: 'Objet du GER titre sur une ligne',
      amount: 23922,
      date: 'Wed, 30 Jun 2021 22:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: true,
      __v: 0,
    },
    {
      _id: '60f7f1541c417e6b40dc2eee',
      updatedAt: '2021-07-21T10:05:08.129Z',
      createdAt: '2021-07-21T10:05:08.129Z',
      description: 'Objet du GER titre sur une ligne',
      amount: 23922,
      date: 'Fri, 09 Jul 2021 22:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: true,
      __v: 0,
    },
    {
      _id: '60f7f15c1c417e6b40dc2eef',
      updatedAt: '2021-07-21T10:05:16.095Z',
      createdAt: '2021-07-21T10:05:16.095Z',
      description: 'Objet du GER titre sur une ligne',
      amount: 23922,
      date: 'Fri, 16 Jul 2021 22:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: true,
      __v: 0,
    },
    {
      _id: '60f7f51c1c417e6b40dc2ef0',
      updatedAt: '2021-07-21T10:21:16.206Z',
      createdAt: '2021-07-21T10:21:16.206Z',
      description: 'test',
      amount: 23922,
      date: 'Sat, 11 Jan 2020 00:00:00 GMT',
      year: '2020',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f7f5341c417e6b40dc2ef1',
      updatedAt: '2021-07-21T10:21:40.208Z',
      createdAt: '2021-07-21T10:21:40.208Z',
      description: 'ddfsfd',
      amount: 23922,
      date: 'Thu, 07 May 2020 23:00:00 GMT',
      year: '2020',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f803521c417e6b40dc2f12',
      updatedAt: '2021-07-21T11:21:54.549Z',
      createdAt: '2021-07-21T11:21:54.549Z',
      description: 'Objet du GER titre sur une ligne',
      amount: 23922,
      date: 'Fri, 07 Jan 2022 00:00:00 GMT',
      year: '2022',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f8039d1c417e6b40dc2f13',
      updatedAt: '2021-07-21T11:23:09.190Z',
      createdAt: '2021-07-21T11:23:09.190Z',
      description: 'Objet du GER titre sur une ligne',
      amount: 23922,
      date: 'Sat, 29 Jan 2022 00:00:00 GMT',
      year: '2022',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f805881c417e6b40dc2f17',
      updatedAt: '2021-07-21T11:31:20.634Z',
      createdAt: '2021-07-21T11:31:20.634Z',
      description: 'dzzdzd',
      amount: 23922,
      date: 'Wed, 06 Apr 2022 23:00:00 GMT',
      year: '2022',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: true,
      __v: 0,
    },
    {
      _id: '60f805f11c417e6b40dc2f18',
      updatedAt: '2021-07-21T11:33:05.825Z',
      createdAt: '2021-07-21T11:33:05.825Z',
      description: 'dzdzdz',
      amount: 23922,
      date: 'Wed, 05 Jan 2022 00:00:00 GMT',
      year: '2022',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: true,
      __v: 0,
    },
    {
      _id: '60f8064f1c417e6b40dc2f19',
      updatedAt: '2021-07-21T11:34:39.042Z',
      createdAt: '2021-07-21T11:34:39.042Z',
      description: 'ssdds',
      amount: 23922,
      date: 'Thu, 07 Apr 2022 23:00:00 GMT',
      year: '2022',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: true,
      __v: 0,
    },
    {
      _id: '60f806b61c417e6b40dc2f1a',
      updatedAt: '2021-07-21T11:36:22.252Z',
      createdAt: '2021-07-21T11:36:22.252Z',
      description: 'zddzz',
      amount: 23922,
      date: 'Wed, 05 Jan 2022 00:00:00 GMT',
      year: '2022',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: true,
      __v: 0,
    },
    {
      _id: '60f806f51c417e6b40dc2f1c',
      updatedAt: '2021-07-21T11:37:25.613Z',
      createdAt: '2021-07-21T11:37:25.613Z',
      description: 'zdzd',
      amount: 23922,
      date: 'Wed, 05 Jan 2022 00:00:00 GMT',
      year: '2022',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: true,
      __v: 0,
    },
    {
      _id: '60f8071d1c417e6b40dc2f1d',
      updatedAt: '2021-07-21T11:38:05.861Z',
      createdAt: '2021-07-21T11:38:05.861Z',
      description: 'dfdfsdqf',
      amount: 23922,
      date: 'Thu, 04 Feb 2021 00:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f8073d1c417e6b40dc2f1e',
      updatedAt: '2021-07-21T11:38:37.880Z',
      createdAt: '2021-07-21T11:38:37.880Z',
      description: 'dzdz',
      amount: 23922,
      date: 'Fri, 15 Jan 2021 00:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f807561c417e6b40dc2f1f',
      updatedAt: '2021-07-21T11:39:02.246Z',
      createdAt: '2021-07-21T11:39:02.246Z',
      description: 'ddzdz',
      amount: 23922,
      date: 'Sat, 02 Jan 2021 00:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f8076a1c417e6b40dc2f20',
      updatedAt: '2021-07-21T11:39:22.692Z',
      createdAt: '2021-07-21T11:39:22.692Z',
      description: 'zd',
      amount: 23922,
      date: 'Sat, 23 Jan 2021 00:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f808fc1c417e6b40dc2f23',
      updatedAt: '2021-07-21T11:46:04.901Z',
      createdAt: '2021-07-21T11:46:04.901Z',
      description: 'zdzd',
      amount: 23922,
      date: 'Thu, 07 Jan 2021 00:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f8094a1c417e6b40dc2f24',
      updatedAt: '2021-07-21T11:47:22.274Z',
      createdAt: '2021-07-21T11:47:22.274Z',
      description: 'zdzd',
      amount: 23922,
      date: 'Sat, 16 Jan 2021 00:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f809e71c417e6b40dc2f25',
      updatedAt: '2021-07-21T11:49:59.609Z',
      createdAt: '2021-07-21T11:49:59.609Z',
      description: 'zdzd',
      amount: 23922,
      date: 'Fri, 08 Jan 2021 00:00:00 GMT',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '60f975135560ba2a5bcdcf4b',
      updatedAt: '2021-07-22T13:39:31.993Z',
      createdAt: '2021-07-22T13:39:31.993Z',
      description: 'test',
      amount: 239,
      date: 'Thu, 02 Jan 2020 00:00:00 GMT',
      year: '2020',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '6103ca0ce6ae6a1e21e94707',
      updatedAt: '2021-07-30T09:44:44.167Z',
      createdAt: '2021-07-30T09:44:44.167Z',
      description: 'test',
      amount: 200,
      date: 'Fri, 31 Dec 2021 00:00:00 GMT',
      year: '2022',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '6103d329e6ae6a1e21e9479d',
      updatedAt: '2021-07-30T10:23:37.900Z',
      createdAt: '2021-07-30T10:23:37.900Z',
      description: 'ZYYY',
      amount: 244,
      date: 'Wed, 05 Jan 2022 00:00:00 GMT',
      year: '2022',
      structure: {
        _id: '6103cf67e6ae6a1e21e9474c',
        name: 'Piscine du Petit-Port',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '6103d337e6ae6a1e21e9479e',
      updatedAt: '2021-07-30T10:23:51.157Z',
      createdAt: '2021-07-30T10:23:51.157Z',
      description: 'zdzdz',
      amount: 23922,
      date: 'Tue, 11 Jan 2022 00:00:00 GMT',
      year: '2022',
      structure: {
        _id: '6103cf67e6ae6a1e21e9474c',
        name: 'Piscine du Petit-Port',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '6103f179e6ae6a1e21e947b1',
      updatedAt: '2021-07-30T12:32:57.768Z',
      createdAt: '2021-07-30T12:32:57.768Z',
      description: 'dfzefe',
      amount: 152,
      date: '2021-01-01T00:00:00.000Z',
      year: '2021',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '6103f207204c6f47f56dfa2e',
      updatedAt: '2021-07-30T12:35:19.378Z',
      createdAt: '2021-07-30T12:35:19.378Z',
      description: 'effe',
      amount: 250,
      date: '2022-01-01T00:00:00.000Z',
      year: '2022',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '6103f20f204c6f47f56dfa2f',
      updatedAt: '2021-07-30T12:35:27.366Z',
      createdAt: '2021-07-30T12:35:27.366Z',
      description: 'testt',
      amount: 23922,
      date: 'Mon, 27 Dec 2021 00:00:00 GMT',
      year: '2022',
      structure: {
        _id: '60d99f81b80bfc1c26670351',
        name: 'piscine municipale de Vertou',
      },
      isProvisional: false,
      __v: 0,
    },
    {
      _id: '6103fa6b8e1afb1ce1276561',
      updatedAt: '2021-07-30T13:11:07.361Z',
      createdAt: '2021-07-30T13:11:07.361Z',
      date: '2020-01-01T00:00:00.000Z',
      description: 'test',
      amount: 23922,
      year: '2020',
      structure: {
        _id: '6103cf67e6ae6a1e21e9474c',
        name: 'Piscine du Petit-Port',
      },
      isProvisional: true,
      __v: 0,
    },
    {
      _id: '6103fa862955e13de27bc709',
      updatedAt: '2021-07-30T13:11:34.903Z',
      createdAt: '2021-07-30T13:11:34.903Z',
      description: 'test',
      amount: 23922,
      date: 'Thu, 02 Jan 2020 00:00:00 GMT',
      year: '2020',
      structure: {
        _id: '6103cf67e6ae6a1e21e9474c',
        name: 'Piscine du Petit-Port',
      },
      isProvisional: false,
      __v: 0,
    },
  ],
};

export const consumptionsData = {
  global: [
    {
      label: 'EAU',
      'Année N-1': getRandomInt(),
      'Année N-2': getRandomInt(),
      Prévisionnel: getRandomInt(),
      Réel: getRandomInt(),
      unit: 'K',
    },
    {
      label: 'GAZ',
      'Année N-1': getRandomInt(),
      'Année N-2': getRandomInt(),
      Prévisionnel: getRandomInt(),
      Réel: getRandomInt(),
      unit: 'K',
    },
    {
      label: 'Eléctricité',
      'Année N-1': getRandomInt(),
      'Année N-2': getRandomInt(),
      Prévisionnel: getRandomInt(),
      Réel: getRandomInt(),
      unit: 'K',
    },
  ],
  total: [
    {
      label: 'ELECTRICITÉ (Mwh/m2)',
      'Année N-1': getRandomInt() * 2,
      'Année N-2': getRandomInt() * 2,
      Réel: getRandomInt() * 2,
      unit: 'K',
    },
    {
      label: 'GAZ (Mwh/m2 SDO)',
      'Année N-1': getRandomInt() * 2,
      'Année N-2': getRandomInt() * 2,
      Réel: getRandomInt() * 2,
      unit: 'K',
    },
  ],
  water: lineData,
};
