import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStructuresAction } from '../../../actions/structuresActions';
import DocumentsList from '../../../components/partials/DocumentsList/DocumentsList';
import styles from './Documents.module.scss';

export default function Documents() {
  const {
    lists, structuresList, files, structure,
  } = useSelector((state) => state.structures);
  const {
    structures,
  } = useSelector((state) => state.visualisation);
  const dispatch = useDispatch();

  const [structuresFiles, setStructuresFiles] = useState([]);

  const getData = useCallback(() => {
    getStructuresAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [structure]);

  useEffect(() => {
    if (structuresList?.length > 0 && files) {
      const filesList = [];
      structuresList?.filter((s) => structures?.find((a) => a === s._id)).forEach((s) => {
        s.files.forEach((f) => {
          filesList.push({
            file: f.file,
            type: f.type,
            structure: s,
          });
        });
      });
      setStructuresFiles(filesList);
    }
  }, [structuresList, structures, files]);

  return (
    <div className={`page-content visualisation ${styles.container}`}>
      <div className={styles.lists}>
        {lists.documents.map((type) => <DocumentsList
          key={type}
          type={type}
          files={structuresFiles?.filter((f) => f.type === type)}
          />)}
      </div>
    </div>
  );
}
