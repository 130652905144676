import { saveAs } from 'file-saver';
import {
  getData, postData, putData, getFile, deleteData,
} from './index';
import {
  ERROR_STRUCTURE,
  GET_STRUCTURES,
  GET_STRUCTURE,
  POST_STRUCTURE,
  POST_USER,
  SET_TOAST,
  GET_USERS,
  GET_FILES,
  POST_FILE,
  DELETE_FILE,
  EXPORT_IS_LOADING,
  GET_LISTS,
  GET_ADMIN_USERS,
  POST_ADMIN_USER,
  PUT_ADMIN_USER,
  DELETE_ADMIN_USER,
} from './types';

export async function getStructuresListsAction(dispatch) {
  const url = '/structure/list';
  let lists;
  await getData(ERROR_STRUCTURE, url, dispatch, true).then((response) => {
    lists = response;
  });
  dispatch({
    type: GET_LISTS,
    payload: lists,
  });
}

export async function getStructuresAction(dispatch) {
  const url = '/structure';
  let structures;
  await getData(ERROR_STRUCTURE, url, dispatch, true).then((response) => {
    structures = response.structures;
  });
  dispatch({
    type: GET_STRUCTURES,
    payload: structures,
  });
}

export async function getStructureAction(dispatch, id) {
  const url = `/structure/${id}`;
  let structure;
  await getData(ERROR_STRUCTURE, url, dispatch, true).then((response) => {
    structure = response.structure;
  });
  dispatch({
    type: GET_STRUCTURE,
    payload: structure,
  });
}

export async function getUsersAction(dispatch) {
  const url = '/user';
  let users;
  await getData(ERROR_STRUCTURE, url, dispatch, true).then((response) => {
    users = response.users;
  });
  dispatch({
    type: GET_USERS,
    payload: users.filter((u) => u.role !== 'Admin'),
  });
  dispatch({
    type: GET_ADMIN_USERS,
    payload: users.filter((u) => u.role === 'Admin'),
  });
}

export async function postStructureAction(dispatch, obj) {
  const url = '/structure';
  let postStructure;
  await postData(GET_STRUCTURE, ERROR_STRUCTURE, url, dispatch, obj, true).then((response) => {
    postStructure = response;
  });

  dispatch({
    type: POST_STRUCTURE,
    payload: postStructure.structure,
  });
}

export async function putStructureAction(dispatch, id, data) {
  const url = `/structure/${id}`;
  let putStructure;

  await putData(GET_STRUCTURE, ERROR_STRUCTURE, url, dispatch, data, true).then((response) => {
    putStructure = response;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'Le site a été modifié',
    },
  });

  dispatch({
    type: GET_STRUCTURE,
    payload: putStructure.structure,
  });
}

export async function postUserAction(dispatch, obj) {
  const url = '/user';
  let user;
  await postData(POST_USER, ERROR_STRUCTURE, url, dispatch, obj, true).then((response) => {
    user = response;
  });
  if (user.user.role === 'Admin') {
    dispatch({
      type: POST_ADMIN_USER,
      payload: user.user,
    });
  } else {
    dispatch({
      type: POST_USER,
      payload: user.user,
    });
  }
}

export async function putUserAction(dispatch, id, data) {
  const url = `/user/${id}`;
  let user;

  await putData(SET_TOAST, ERROR_STRUCTURE, url, dispatch, data, true).then((response) => {
    user = response;
  });
  if (data?.role === 'Admin') {
    dispatch({
      type: PUT_ADMIN_USER,
      payload: {
        ...data,
        _id: id,
      },
    });
  }
  if (user) {
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: "L'utilisteur a été modifié",
      },
    });
  }
}

export async function deleteAdminAction(dispatch, id) {
  const url = `/user/${id}`;
  let user;

  await deleteData(ERROR_STRUCTURE, url, dispatch, true).then((response) => {
    user = response;
  });
  dispatch({
    type: DELETE_ADMIN_USER,
    payload: id,
  });

  if (user) {
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: "L'administrateur a été supprimé",
      },
    });
  }
}

export async function postFileAction(dispatch, obj) {
  const url = '/file';
  let file;
  await postData(POST_FILE, ERROR_STRUCTURE, url, dispatch, obj, true).then((response) => {
    file = response;
  });

  dispatch({
    type: POST_FILE,
    payload: file.files[0],
  });
}

export async function getFilesAction(dispatch) {
  const url = '/file';
  let files;
  await getData(ERROR_STRUCTURE, url, dispatch, true).then((response) => {
    files = response.files;
  });
  dispatch({
    type: GET_FILES,
    payload: files,
  });
}

export async function deleteFileAction(dispatch, id) {
  console.log(id);
  const url = `/file/${id}`;
  let deleteFile;

  await deleteData(ERROR_STRUCTURE, url, dispatch, true).then((response) => {
    deleteFile = response;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'Le fichier a été supprimé',
    },
  });
  if (deleteFile) {
    dispatch({
      type: DELETE_FILE,
      payload: id,
    });
  }
}

export async function getFileAction(dispatch, id, downloadName = false, fileType = null) {
  const url = `/file/download/${id}`;

  await getFile(ERROR_STRUCTURE, url, dispatch, true).then((response) => {
    if (!downloadName && !fileType) {
      const fileURL = URL.createObjectURL(response);
      window.open(fileURL);
    } else {
      const blob = new Blob([response.data], { type: 'application/octet-stream' });
      saveAs(blob, downloadName);
    }
  });
}

export async function postExportAction(dispatch, obj, name = 'export') {
  const url = '/export';
  let exportData;
  await postData(
    EXPORT_IS_LOADING,
    ERROR_STRUCTURE,
    url,
    dispatch,
    obj,
    true,
    // USE LOCAL URL
    // true,
  ).then((response) => {
    exportData = response;
  });

  let ext = 'pdf';

  if (obj.type === 'xlsx') {
    if (obj.structures.length > 1) {
      ext = 'zip';
    } else {
      ext = 'xlsx';
    }
  }

  const blob = new Blob([exportData], { type: 'application/octet-stream' });
  const fileName = `${name}.${ext}`;
  saveAs(blob, fileName);
  dispatch({
    type: EXPORT_IS_LOADING,
    payload: false,
  });
}
