import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SynthesisValue from '../../../components/partials/SynthesisValue/SynthesisValue';
// import Notifications from '../../../components/partials/Notifications/Notifications';
import styles from './Synthesis.module.scss';
import { getSynthesisAction } from '../../../actions/visualisationActions';

export default function Synthesis() {
  const { synthesis, filters } = useSelector((state) => state.visualisation);
  const dispatch = useDispatch();
  const getData = useCallback(() => {
    getSynthesisAction(dispatch, filters);
  }, [dispatch, filters]);

  useEffect(() => {
    if (filters.structures?.length) {
      getData();
    }
  }, [filters]);

  return (
    <div className={`page-content visualisation ${styles.container}`}>
      {synthesis
      && <>
        <div className={styles.synthesis}>
          {synthesis?.map((d) => <SynthesisValue key={d.label} data={d} />)}
        </div>
        {/* <div className={styles.notifs}>
          <Notifications />
        </div> */}
      </>
      }
    </div>
  );
}
