import {
  postData, putData, getData, deleteData,
} from './index';

import {
  ERROR_STRUCTURE,
  SET_TOAST,
  POST_STRUCTURE_DATA,
  PUT_CONSUMPTION,
  PUT_SALE,
  PUT_PAYROLL,
  POST_EXPENSE,
  DELETE_EXPENSE,
  PUT_FREQUENTATION,
  PUT_FILLING,
} from './types';

export async function postStructureDataAction(dispatch, obj) {
  const url = '/structure/data';
  let data;
  dispatch({
    type: POST_STRUCTURE_DATA,
    payload: null,
  });
  await postData(
    POST_STRUCTURE_DATA,
    ERROR_STRUCTURE,
    url,
    dispatch,
    obj,
    true,
  ).then((response) => {
    data = response;
  });

  dispatch({
    type: POST_STRUCTURE_DATA,
    payload: data.structure.data,
  });
}

export async function getExpensesAction(dispatch) {
  const url = '/expense';
  let expenses;
  await getData(ERROR_STRUCTURE, url, dispatch, true).then((response) => {
    expenses = response.expenses;
  });
  dispatch({
    type: POST_STRUCTURE_DATA,
    payload: expenses,
  });
}

export async function putConsumptionAction(dispatch, id, data) {
  const url = `/consumption/${id}`;
  let putConsumption;

  await putData(PUT_CONSUMPTION, ERROR_STRUCTURE, url, dispatch, data, true).then((response) => {
    putConsumption = response;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'Les consommations ont été modifiées',
    },
  });

  dispatch({
    type: PUT_CONSUMPTION,
    payload: putConsumption.consumption,
  });
}

export async function putSaleAction(dispatch, id, data) {
  const url = `/sale/${id}`;
  let putSale;

  await putData(PUT_SALE, ERROR_STRUCTURE, url, dispatch, data, true).then((response) => {
    putSale = response;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: "Les chiffres d'affaires ont été modifiées",
    },
  });

  dispatch({
    type: PUT_SALE,
    payload: putSale.sale,
  });
}

export async function putPayrollAction(dispatch, id, data) {
  const url = `/payroll/${id}`;
  let payroll;

  await putData(PUT_PAYROLL, ERROR_STRUCTURE, url, dispatch, data, true).then((response) => {
    payroll = response;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'Les masses salariales ont été modifiées',
    },
  });

  dispatch({
    type: PUT_PAYROLL,
    payload: payroll.payroll,
  });
}

export async function putFrequentationAction(dispatch, id, data) {
  const url = `/frequentation/${id}`;
  let putFrequentation;

  await putData(PUT_FREQUENTATION, ERROR_STRUCTURE, url, dispatch, data, true).then((response) => {
    putFrequentation = response;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'Les fréquentations ont été modifiées',
    },
  });

  dispatch({
    type: PUT_FREQUENTATION,
    payload: putFrequentation.frequentation,
  });
}

export async function putFillingAction(dispatch, id, data) {
  const url = `/filling/${id}`;
  let putFilling;

  await putData(PUT_FILLING, ERROR_STRUCTURE, url, dispatch, data, true).then((response) => {
    putFilling = response;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'Les taux de remplissage ont été modifiées',
    },
  });

  dispatch({
    type: PUT_FILLING,
    payload: putFilling.filling,
  });
}

export async function postExpenseAction(dispatch, obj) {
  const url = '/expense';
  let data;
  await postData(
    POST_EXPENSE,
    ERROR_STRUCTURE,
    url,
    dispatch,
    obj,
    true,
  ).then((response) => {
    data = response;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'Les dépenses ont été modifiées',
    },
  });

  dispatch({
    type: POST_EXPENSE,
    payload: data.expense,
  });
}

export async function deleteExpenseAction(dispatch, id) {
  const url = `/expense/${id}`;
  let deleteExpense;

  await deleteData(ERROR_STRUCTURE, url, dispatch, true).then((response) => {
    deleteExpense = response;
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'La dépense a été supprimée',
    },
  });
  if (deleteExpense) {
    dispatch({
      type: DELETE_EXPENSE,
      payload: id,
    });
  }
}
