import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';

import { putFillingAction } from '../../../../actions/formsActions';

import { months } from '../../../../constants/constants';

import FillingRow from './FillingRow/FillingRow';

import styles from './FillingSubType.module.scss';

export default function FillingSubType({
  subType,
  name,
  panelHeight,
  setPanelHeight,
}) {
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const autoSaveTimer = useRef();

  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [contentList, setContentList] = useState();
  const [form, setForm] = useState({});

  function saveWithTimer(data) {
    clearTimeout(autoSaveTimer.current);
    autoSaveTimer.current = setTimeout(() => putFillingAction(dispatch, subType._id, data), 3000);
  }

  function handleChange(key, value) {
    const data = { ...form, [key]: value };
    setForm(data);
    saveWithTimer(data);
  }

  useEffect(() => {
    if (contentRef?.current?.clientHeight > 0 && panelHeight > 0) {
      if (panelIsOpen) {
        setPanelHeight(panelHeight + contentRef?.current?.clientHeight);
      } else {
        setPanelHeight(panelHeight - contentRef?.current?.clientHeight);
      }
    }
  }, [panelIsOpen]);

  useEffect(() => {
    if (subType?.content) {
      const list = [];
      Object.entries(subType.content).forEach(([key, value]) => {
        list.push({
          _id: `${subType._id}-${key}`,
          key,
          label: months[key],
          isProvisional: subType.isProvisional,
          unit: '%',
          value,
        });
      });
      setForm({});
      setContentList([...list.reverse()]);
    }
  }, [subType]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>{name}</h2>
        <button
          onClick={() => setPanelIsOpen(!panelIsOpen)}
          >
          {panelIsOpen ? <RiArrowUpSLine size={28} color={'#192243'}/> : <RiArrowDownSLine size={28} color={'#192243'}/>}
        </button>
      </div>
      <div
        className={styles.panel}
        style={{
          height: panelIsOpen && contentRef?.current?.clientHeight > 0
            ? contentRef.current.clientHeight : 0,
        }}>
        <div >
          <div
            ref={contentRef}
            className={styles.content}>
            <div className={styles.labels}>
              <div className={styles.col}>
                <p>Semaine 1</p>
              </div>
              <div className={styles.col}>
                <p>Semaine 2</p>
              </div>
              <div className={styles.col}>
                <p>Semaine 3</p>
              </div>
              <div className={styles.col}>
                <p>Semaine 4</p>
              </div>
            </div>
            {contentList?.map((s) => <FillingRow
              key={s._id}
              monthData={s}
              handleChange={handleChange}
            />)}
          </div>
        </div>
      </div>
    </div>
  );
}
