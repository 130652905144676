import React from 'react';
import { BiLoader } from 'react-icons/bi';
import { MdCloudDownload } from 'react-icons/md';
import styles from './InputFile.module.scss';

export default function InputFile({
  onChange, name, label, ref, isLoading, type, withErrors, error,
}) {
  return (
    <div className={`${styles.container} ${type ? styles[type] : ''}`}>
      <label>{label}</label>
      {isLoading
        ? <label className={styles.btn}>
          <div className={styles.loader}><BiLoader size={20} /></div>
          Traitement en cours
        </label>
        : <label htmlFor={name} className={styles.btn}>
          <MdCloudDownload size={20} />
          Importer le fichier
        </label>
      }
      <input ref={ref || null } type="file" name={name} onChange={(e) => onChange(e.target.files) } id={name} />
      {withErrors && <p className={styles['error-message']}>{error && error}</p>}
    </div>
  );
}
