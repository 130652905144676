import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SET_CONFIG, POST_STRUCTURE_DATA } from '../../../actions/types';
import { postStructureDataAction } from '../../../actions/formsActions';

import SalesYear from '../../../components/partials/SalesYear/SalesYear';

import styles from './Sales.module.scss';

export default function Sales() {
  const {
    config, data,
  } = useSelector((state) => state.structures);
  const dispatch = useDispatch();

  const initConfig = useCallback(() => {
    dispatch({
      type: SET_CONFIG,
      payload: { ...config, type: 'sale' },
    });
    dispatch({
      type: POST_STRUCTURE_DATA,
      payload: null,
    });
  }, [dispatch]);

  const getData = useCallback(() => {
    postStructureDataAction(dispatch, config);
  }, [dispatch, config]);

  useEffect(() => {
    initConfig();
  }, []);

  useEffect(() => {
    if (config?.structure && config?.year && config?.type === 'sale') {
      getData();
    }
  }, [config.year, config?.structure, config?.type, config?.isProvisional]);

  const sales = data?.filter((d) => d.isProvisional === config.isProvisional);

  return (
    <div className={`page-content ${styles.container}`}>
      {config?.type === 'sale' && sales?.map((s) => <SalesYear
        key={s._id}
        id={s._id}
        name={s.type}
        isProvisional={s.isProvisional}
        provisionalContent={
          data?.find((d) => d.isProvisional && !s.isProvisional && d.type === s.type)?.content
        }
        content={s.content}
      />)}
    </div>
  );
}
