import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAuthAction } from '../../actions/authActions';
import styles from './Auth.module.scss';
import { getStructuresAction } from '../../actions/structuresActions';

export default function SwitchIndex() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userAuth, error } = useSelector((state) => state.auth);
  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
    getStructuresAction(dispatch);
  }, [dispatch]);

  const clearError = useCallback(() => {
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    getUser();
    return () => {
      clearError();
    };
  }, [getUser, clearError]);

  useEffect(() => {
    if (error) {
      clearError();
      history.push('/login');
    }
  }, [userAuth, error, history]);

  return (
    <div className={styles.container}>
    </div>
  );
}
