import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillDelete } from 'react-icons/ai';
import { deleteFileAction, getFileAction, putStructureAction } from '../../../actions/structuresActions';
import styles from './DocumentRow.module.scss';

export default function DocumentRow({ file, structure }) {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.auth);

  async function deleteFile(id) {
    await deleteFileAction(dispatch, id);
    await putStructureAction(
      dispatch,
      structure?._id,
      {
        files: structure.files.filter((f) => f.file._id !== id),
      },
    );
  }

  return (
    <div
      className={styles.container}>
      <div className={`${styles.col} ${styles.name}`}>
        <p>{file?.name}</p>
      </div>
      <div className={styles.col}>
        <p>{structure?.name}</p>
      </div >
      <div className={`${styles.col} ${styles.actions}`}>
        <button
          className={styles.download}
          onClick={() => getFileAction(dispatch, file?._id)}>
          Télécharger
        </button>
        {role !== 'Member'
          && <button
            className={styles.delete}
            onClick={() => deleteFile(file?._id)}
            >
            <AiFillDelete size={20}/>
          </button>
        }
      </div>
    </div>
  );
}
