import React, { useState, useRef } from 'react';
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';

import Button from '../../lib/Button';
import DocumentRow from '../DocumentRow/DocumentRow';
import Modale from '../../lib/Modale';
import styles from './DocumentsList.module.scss';
import DocumentModale from '../DocumentModale/DocumentModale';

export default function DocumentsList({
  type,
  files,
}) {
  const { role } = useSelector((state) => state.auth);
  const contentRef = useRef(null);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [modaleIsOpen, setModaleIsOpen] = useState();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2 className={`${panelIsOpen ? styles.primary : ''}`}>{type}</h2>
          <div className={styles.buttons}>
            {panelIsOpen && role !== 'Member'
              && <Button
                primary
                size={'xs'}
                handleClick={() => setModaleIsOpen(true)}
                >
                Ajouter un document
              </Button>
            }
            <button
              className={styles['panel-toggle']}
              onClick={() => setPanelIsOpen(!panelIsOpen)}
              >
              {panelIsOpen ? <RiArrowUpSLine size={28} color={'#192243'}/> : <RiArrowDownSLine size={28} color={'#192243'}/>}
            </button>
          </div>
        </div>
        <div
          className={styles.panel}
          style={{
            height: panelIsOpen && contentRef?.current?.clientHeight > 0
              ? contentRef.current.clientHeight : 0,
          }}>
          <div ref={contentRef}>
            <div className={styles.content}>
              {files.length > 0 && files?.map((f) => <DocumentRow
                key={f._id}
                file={f.file}
                structure={f?.structure}
              />)}
            </div>
          </div>
        </div>
    </div>
    {role !== 'Member'
      && <Modale
        isOpen={modaleIsOpen}
        modaleToggle={() => setModaleIsOpen(false)}
        >
        {modaleIsOpen
          && <DocumentModale
            type={type}
            setModaleIsActive={setModaleIsOpen}
          />
        }
      </Modale>
      }
    </>
  );
}
