import React, { useState, useEffect } from 'react';
import useDimensions from 'react-use-dimensions';
import styles from './FillingRow.module.scss';

export default function FillingRow({
  monthData,
  handleChange,
}) {
  const [value, setValue] = useState(monthData.value);
  const [ref, { width }] = useDimensions();
  useEffect(() => {
    setValue(monthData.value);
  }, [monthData.value]);

  function onChange(key, e) {
    let val = e.target.value;
    val = parseFloat(val);
    setValue({ ...value, [key]: Number.isNaN(val) ? '' : val });
    handleChange(`content.${monthData.key}.${key}`, Number.isNaN(val) ? null : val);
  }

  function foundValues() {
    if (value?.week1 === ''
      && value?.week2 === ''
      && value?.week3 === ''
      && value?.week4 === '') {
      return true;
    }
    if (value?.week1 === null
      && value?.week2 === null
      && value?.week3 === null
      && value?.week4 === null) {
      return true;
    }
    return false;
  }

  return (
    <div
      ref={ref}
      className={styles.container}
      style={{ color: foundValues() ? '#B1B1BF' : '#181844' }}
      >
      <div className={styles.label}>
        <p>{monthData.isProvisional
          && <span >Prév<span className={styles.prev}>isionnel</span></span>}
        </p>
        <p>{width > 800 ? monthData.label : monthData.label.substring(0, 3)}</p>
      </div>
      <div className={styles.input}>
        <input
          style={{ color: value?.week1 !== null ? '#181844' : '#B1B1BF' }}
          value={value?.week1 >= 0 ? value?.week1 : ''}
          type="number"
          onChange={(e) => onChange('week1', e)}
          />
        <div className={styles.unit}>
          {monthData.unit}
        </div>
      </div>
      <div className={styles.input}>
        <input
          style={{ color: value?.week2 !== null ? '#181844' : '#B1B1BF' }}
          value={value?.week2 >= 0 ? value?.week2 : ''}
          type="number"
          onChange={(e) => onChange('week2', e)}
          />
        <div className={styles.unit}>
          {monthData.unit}
        </div>
      </div>
      <div className={styles.input}>
        <input
          style={{ color: value?.week3 !== null ? '#181844' : '#B1B1BF' }}
          value={value?.week3 >= 0 ? value?.week3 : ''}
          type="number"
          onChange={(e) => onChange('week3', e)}
          />
        <div className={styles.unit}>
          {monthData.unit}
        </div>
      </div>
      <div className={styles.input}>
        <input
          style={{ color: value?.week4 !== null ? '#181844' : '#B1B1BF' }}
          value={value?.week4 >= 0 ? value?.week4 : ''}
          type="number"
          onChange={(e) => onChange('week4', e)}
          />
        <div className={styles.unit}>
          {monthData.unit}
        </div>
      </div>
    </div>
  );
}
