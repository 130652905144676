import React from 'react';
import reactDom from 'react-dom';
import { MdClose } from 'react-icons/md';

import styles from './Modale.module.scss';

export default function Modale({
  isOpen, modaleToggle, className, hideClose, children,
}) {
  return reactDom.createPortal(
    <div className={`${styles['modale-container']} ${isOpen ? styles['is-active'] : ''}`} >
      <div className={`${className || ''} ${styles.modale} ${isOpen ? styles['is-active'] : ''}`}>
        {!hideClose && <button
            onClick={() => modaleToggle(false)}
            className={styles.close}>
            <MdClose size={20} color={'#656565'} />
          </button>
        }
        {children}
      </div>
    </div>,
    document.getElementById('modal-root'),
  );
}
