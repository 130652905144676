import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { postStructureAction } from '../../../actions/structuresActions';

import Button from '../../lib/Button';
import InputText from '../../lib/InputText';
import ErrorField from '../../lib/ErrorField';

import styles from './CreateStructureModale.module.scss';

export default function CreateStructureModale() {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm();

  function submitStructure(values) {
    return postStructureAction(dispatch, values);
  }

  return (
    <div className={styles.container}>
      <h1>Ajouter un site</h1>
      <div className={styles.input}>
        <InputText
          name='name'
          label='Nom du site'
          control={control}
          className={'primary'}
          rules={{ required: 'Le nom est requis' }}
          placeholder={'Nom du site'}
          />
        <ErrorField message={errors.name?.message}/>
      </div>
      <Button
        handleClick={handleSubmit(submitStructure)}
        >
        Ajouter le site
      </Button>
    </div>
  );
}
