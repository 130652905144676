import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConsumptionAction } from '../../../actions/visualisationActions';
// import
// ConsumptionBars from '../../../components/partials/Charts/ConsumptionBars/ConsumptionBars';
import ConsumptionCharts from '../../../components/partials/Charts/ConsumptionCharts/ConsumptionCharts';
import GroupedBarChart from '../../../components/partials/Charts/GroupedBarChart/GroupedBarChart';
import Table from '../../../components/partials/Charts/Table';
import SelectGraph from '../../../components/partials/SelectGraph';
// import LineChartBloc from '../../../components/partials/Charts/LineChartBloc/LineChartBloc';

import { colors } from '../../../constants/constants';

import styles from './Consumption.module.scss';

export default function Consumption() {
  const { consumption, filters } = useSelector((state) => state.visualisation);
  const dispatch = useDispatch();
  const [water, setWater] = useState();

  const getData = useCallback(() => {
    getConsumptionAction(dispatch, filters);
  }, [dispatch, filters]);

  useEffect(() => {
    if (filters?.structures?.length > 0) {
      getData();
    }
  }, [filters]);

  useEffect(() => {
    if (consumption?.water.length > 0) {
      const waterData = consumption.water;
      if (waterData[0]?.data) {
        const yearData = [];
        waterData.forEach((p) => {
          p?.data.forEach((d) => {
            const index = yearData.findIndex((y) => y.month === d.x);

            if (index !== -1) {
              yearData[index][p.id] = d.y;
            } else {
              yearData.push({
                month: d.x,
                [p.id]: d.y,
              });
            }
          });
        });
        setWater(yearData);
      } else {
        setWater(waterData);
      }
    }
  }, [consumption?.water, filters]);

  return (
    <div className={`page-content visualisation ${styles.container}`}>
      {consumption?.global
        && <div className={styles.block}>
          <SelectGraph
            label='Consommation globale'
          >
          {({ isGraph }) => (
            <>
            {isGraph
              ? <ConsumptionCharts
                data={consumption.global}
                colors={colors}
                legend
                indexBy='label'
                keys={['Réel', 'Prévisionnel', 'Période de comparaison']}
                />
              : <div className={styles.tables}>
                  <Table
                    data={consumption.global}
                    colors={colors}
                    legend
                    indexBy='label'
                    keys={['Réel', 'Prévisionnel', 'Période de comparaison']}
                  />
              </div>
            }
            </>
          )}
          </SelectGraph>
        </div>
      }
      {water
        && <div className={`${styles.block} ${styles.water}`}>
           <GroupedBarChart
              data={water}
              colors={colors}
              unit={'L'}
              padding={0.2}
              margin={{
                top: 20, right: 10, bottom: 50, left: 70,
              }}
              enableLabel={false}
              indexBy='month'
              keys={['Réel', 'Prévisionnel', 'Période de comparaison']}
              label='EAU / BAIGNEUR'
              />
        </div>
      }
    </div>
  );
}
