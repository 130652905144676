import React, { useState } from 'react';
import GroupedBarChart from '../Charts/GroupedBarChart/GroupedBarChart';
import Table from '../Charts/Table';
import graphIcon from './images/graph.svg';
import tableIcon from './images/table.svg';
import styles from './SelectGraph.module.scss';

export default function SelectGraph({
  data,
  colors,
  unit,
  margin,
  legend,
  indexBy,
  keys,
  total,
  label,
  children,
}) {
  const [isGraph, setIsGraph] = useState(true);
  return (
    <div className={styles.container}>
      <div className={styles.toggle}>
        <button
          type="button"
          onClick={() => setIsGraph(!isGraph)}
        >
          <img src={isGraph ? graphIcon : tableIcon} alt="alt" />
        </button>
        <label>{label}</label>
      </div>
      {children && children({ isGraph })}
      {!children && isGraph
        && <div className={styles.graph}>
            <GroupedBarChart
              data={data}
              colors={colors}
              unit={unit}
              margin={margin}
              legend={legend}
              indexBy={indexBy}
              keys={keys}
              total={total}
            />
        </div>
      }
      {!children && !isGraph
        && <div className={styles.table}>
            <Table
              data={data}
              unit={unit}
              indexBy={indexBy}
              keys={keys}
            />
        </div>
      }
    </div>
  );
}
