import React, { useState, useRef, useEffect } from 'react';
import { RiArrowUpSLine, RiArrowDownSLine, RiCheckFill } from 'react-icons/ri';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { putQuestionAction } from '../../../actions/visualisationActions';
import TextArea from '../../lib/Textarea';
import Button from '../../lib/Button';
import ErrorField from '../../lib/ErrorField';
import styles from './Notif.module.scss';

export default function Notif({ notif }) {
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const { role } = useSelector((state) => state.auth);
  const [content, setContent] = useState();
  const dispatch = useDispatch();
  const contentRef = useRef();
  const {
    control,
    handleSubmit,
    watch,
    formState: {
      errors,
    },
  } = useForm();

  function submitAnswer(values) {
    putQuestionAction(dispatch, notif._id, values);
    setPanelIsOpen(false);
  }

  useEffect(() => {
    if (watch('answer') && content) {
      setContent(watch('answer'));
    }
  }, [watch('answer')]);
  return (
    <div className={`${styles.container} ${notif.answer ? styles['is-anwser'] : ''}`}>
      <div className={styles.header}>
        <div className={styles.profile}>{notif.answer && <RiCheckFill color="#33C597" size={20}/> }
          <p>{notif?.userQuestion?.profile?.firstName} {notif?.userQuestion?.profile?.lastName}</p>
        </div>
        <button
          onClick={() => setPanelIsOpen(!panelIsOpen)}
          >
          {panelIsOpen ? <RiArrowUpSLine size={28} color={'#192243'}/> : <RiArrowDownSLine size={28} color={'#192243'}/>}
        </button>
      </div>
      <div
        className={styles.panel}
        style={{
          height: panelIsOpen && contentRef?.current?.clientHeight > 0
            ? contentRef.current.clientHeight : 0,
        }}>
        <div ref={contentRef}>
          <div className={styles.content}>
            <p>{notif.question}</p>
            {notif.answer
              ? <div className={styles['answer-container']}>
                  <p className={styles.answer}>{`Réponse de ${notif?.userAnswer?.profile?.firstName} ${notif?.userAnswer?.profile?.lastName}`} :<br/>{notif.answer}</p>
                </div>
              : <>
                {role !== 'Member'
                  && <div className={styles.textarea}>
                    <TextArea
                      name="answer"
                      rules={{ required: 'la réponse est requise' }}
                      control={control}
                      />
                    <ErrorField message={errors.answer?.message}/>
                    <div className={styles.btn}>
                      <Button
                        handleClick={handleSubmit(submitAnswer)}
                        primary
                        size='xs'
                        >
                        Répondre
                      </Button>
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
