import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { SET_CONFIG } from '../../../actions/types';
import {
  getExpensesAction,
  postExpenseAction,
} from '../../../actions/formsActions';

import ExpenceRow from '../../../components/partials/ExpenseRow/ExpenseRow';
import InputText from '../../../components/lib/InputText';
import InputNumberFormat from '../../../components/lib/InputFormatNumber';
// import InputNumber from '../../../components/lib/InputNumber';
import DatePicker from '../../../components/lib/DatePicker';
import ErrorField from '../../../components/lib/ErrorField';
import Button from '../../../components/lib/Button';

import styles from './Expense.module.scss';

export default function Expense() {
  const {
    config, data,
  } = useSelector((state) => state.structures);
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: {
      errors,
    },
  } = useForm();

  const [expenses, setExpenses] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const initConfig = useCallback(() => {
    dispatch({
      type: SET_CONFIG,
      payload: { ...config, type: 'expense' },
    });
  }, [dispatch]);

  const getData = useCallback(() => {
    getExpensesAction(dispatch);
  }, [dispatch]);

  function postExpense(values) {
    setIsLoading(true);
    reset();
    const postData = {
      ...values,
      year: config.year,
      structure: config.structure,
      isProvisional: config.isProvisional,
    };
    postExpenseAction(dispatch, postData);
  }

  useEffect(() => {
    initConfig();
  }, []);

  useEffect(() => {
    if (config?.structure && config?.year && config?.type === 'expense') {
      getData();
    }
  }, [config.year, config?.structure, config?.type, config?.isProvisional]);

  useEffect(() => {
    setValue('date', new Date(`${config.year}-01-01`));
  }, [config.year]);

  useEffect(() => {
    if (data) {
      const filtredExpenses = data.filter((e) => e.structure === config.structure
        || e.structure?._id === config.structure)
        .filter((e) => e.isProvisional === config.isProvisional
        && e.year === config.year.toString());
      setExpenses(filtredExpenses);
      setIsLoading(false);
    }
  }, [data]);

  return (
    <div className={`page-content ${styles.container}`}>
      {expenses && <div className={styles.content}>
        <div className={`${styles.row} ${styles.labels}`}>
          <div className={`${styles.col} ${styles.name}`}>
            <p>Objet du GER</p>
          </div>
          <div className={styles.col}>
            <p>Date</p>
          </div>
          <div className={styles.col}>
            <p>Montant</p>
          </div>
        </div>
        {expenses?.sort((a, b) => new Date(a.date) - new Date(b.date))
          .reverse().map((e) => <ExpenceRow
          key={e._id}
          expense={e}
        />)}
        <form>
          <div className={styles.row}>
            <div className={`${styles.col} ${styles.name}`}>
              <InputText
                name='description'
                control={control}
                className={'primary'}
                rules={{ required: 'la description est requise' }}
                placeholder={'Objet du GER'}
                />
              <ErrorField message={errors.description?.message}/>
            </div>
            <div className={`${styles.col}`}>
              <DatePicker
                value={watch('date') ? new Date(watch('date')) : new Date(`${config.year}-01-01`) }
                className={'primary'}
                onChange={(date) => setValue('date', date.toUTCString())}
                placeholder={'Date'}
                />
              <ErrorField message={!watch('date') && watch('description') && watch('amount') ? 'la date est requise' : ''}/>
            </div>
            <div className={`${styles.col}`}>
            <InputNumberFormat
              name='amount'
              control={control}
              className={'primary'}
              rules={{ required: 'le montant est requis' }}
              placeholder={'Montant'}
              />
              {/* <InputNumber
                name='amount'
                control={control}
                className={'primary'}
                rules={{ required: 'le montant est requis' }}
                placeholder={'Montant'}
                /> */}
              <ErrorField message={errors.amount?.message}/>
            </div>
          </div>
          <div className={styles.btn}>
            <Button
              handleClick={handleSubmit(postExpense)}
              isLoading={isLoading}>
              Ajouter une dépense
            </Button>
          </div>
        </form>
      </div>
    }
    </div>
  );
}
