import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoArrowBackSharp } from 'react-icons/io5';

import { useDispatch, useSelector } from 'react-redux';
import UsersModale from '../../../components/partials/UserModale/UserModale';
import Modale from '../../../components/lib/Modale';
import Button from '../../../components/lib/Button';

import styles from './AdminPage.module.scss';
import { getUsersAction } from '../../../actions/structuresActions';
import UserRow from '../../../components/partials/UserRow/UserRow';

export default function AdminPage() {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.admin);
  const [modaleIsOpen, setModaleIsOpen] = useState();
  useEffect(() => {
    getUsersAction(dispatch);
  }, []);

  return (
    <div className={`page-content admin ${styles.container}`}>
      <header>
        <div className={styles.title}>
          <Link to={'/admin'}>
            <IoArrowBackSharp size={38} />
          </Link>
          <h1>Gestion des administrateurs</h1>
        </div>
      </header>
      <div className={styles.users}>
        <label>Équipe</label>
        <div className={styles.list}>
          {users?.map((u) => <UserRow
            key={u._id}
            user={{ user: u, role: u.role }}
            editUser={(val) => setModaleIsOpen(val)}
            />)}
        </div>
        <Button
          handleClick={() => setModaleIsOpen({})}
          >
          Ajouter un utilisateur
        </Button>
        <Modale
          isOpen={modaleIsOpen}
          modaleToggle={setModaleIsOpen}
          >
          {modaleIsOpen && <UsersModale
            setUserData={setModaleIsOpen}
            userData={modaleIsOpen}
            />}
        </Modale>
      </div>
    </div>
  );
}
