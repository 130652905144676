import React from 'react';
import { useTheme } from '@nivo/core';

import { MdAccessibility } from 'react-icons/md';

import BarChart from '../../../lib/BarChart/BarChart';

import styles from './GroupedBarChart.module.scss';
import { numberWithSpaces } from '../../../../utils/utils';

export default function GroupedBarChart({
  data,
  indexBy,
  unit,
  label,
  total,
  colors,
  maxValue = 'auto',
  margin = {
    top: 45, right: 10, bottom: 50, left: 60,
  },
  enableLabel = false,
  padding = 0.3,
  legend,
  period = 'year',
  keys,
}) {
  const renderLegendLabel = (key) => {
    if (key === 'Année N-2' && period !== 'year') {
      return 'Mois -2';
    }
    if (key === 'Année N-1' && period !== 'year') {
      return 'Mois -1';
    }
    return key;
  };
  const CustomTickBottom = (tick) => {
    const theme = useTheme();
    return (
      <g transform={`translate(${tick.x},${tick.y + 30})`}>
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            ...theme.axis.ticks.text,
            fill: '#181844',
            fontSize: 14,
            fontFamily: 'Montserrat-SemiBold',
          }}
        >
        {tick.value}
        </text>
      </g>
    );
  };

  const CustomTickLeft = (tick) => {
    const theme = useTheme();
    return (
      <g transform={`translate(${tick.x - 10},${tick.y})`}>
        <text
          textAnchor="end"
          dominantBaseline="middle"
          style={{
            ...theme.axis.ticks.text,
            fill: '#A1A0AF',
            fontSize: 14,
            fontFamily: 'Montserrat-Regular',
          }}
        >
        {unit === 'K' || unit === 'k€' ? tick.value / 1000 : tick.value} {unit}
        </text>
      </g>
    );
  };

  const Tooltip = (d) => (
    <div
      style={{
        padding: 8,
        zIndex: 100,
        color: '#181844',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Montserrat-SemiBold',
        background: '#FFFFFF',
        borderRadius: 3,
        boxShadow: '0px 10px 28px #b8b8ba',
      }}
      >
      <div style={{
        width: 20,
        height: 20,
        backgroundColor: d.color,
        marginRight: 10,
        borderRadius: 3,
      }}>

      </div>
      <strong>
        <span style={{ color: '#181844' }}>{renderLegendLabel(d.id)} : {unit !== '%' && unit !== 'K' && unit !== 'k€' ? numberWithSpaces(d.value.toFixed(2)) : null}  {unit === '%' && d.value.toFixed(2)} {unit === 'K' || unit === 'k€' ? numberWithSpaces(d.value / 1000) : null} {unit}</span><br/>
      </strong>
    </div>
  );
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <p>{label}</p>
        {legend
          && <div className={styles.legend}>
            {keys?.map((k) => (
              <div
                key={k}
                className={styles.key}>
                <div
                  className={styles.square}
                  style={{
                    backgroundColor: colors[k],
                  }}
                  >
                </div>
                <p>{renderLegendLabel(k)}</p>
              </div>
            ))}
          </div>
        }
      </div>
      {total > 0 && <div className={styles.total}>
        <p>Total</p>
        <p className={styles.value}>
          {numberWithSpaces(total)} <MdAccessibility size={24}/>
        </p>
      </div>}
      <div className={styles['chart-container']}>
        <div
          className={styles.chart}
          style={{
            minWidth: '100%',
            width: data?.length * 160,
          }}
        >
          <BarChart
            indexBy={indexBy}
            data={data}
            margin={margin}
            keys={keys}
            maxValue={maxValue}
            padding={padding}
            enableLabel={enableLabel}
            axisBottom={{
              renderTick: CustomTickBottom,
            }}
            axisLeft={{
              renderTick: CustomTickLeft,
            }}
            tooltip={Tooltip}
            colors={(d) => colors[d.id]}
          />
        </div>
      </div>
    </div>
  );
}
