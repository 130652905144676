import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

import styles from './BarChart.module.scss';

export default function BarChart({
  data,
  indexBy,
  colors = { scheme: 'nivo' },
  tooltip = null,
  keys,
  padding = 0.3,
  enableLabel = false,
  maxValue = 'auto',
  minValue = 'auto',
  margin = {
    top: 45, right: 10, bottom: 50, left: 60,
  },
  axisLeft = {
    tickSize: 0,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: -40,
  },
  axisBottom = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendPosition: 'middle',
    legendOffset: 32,
  },
}) {
  return (
    <div className={styles.container}>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={margin}
        padding={padding}
        innerPadding={7}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        valueFormat={{ format: '', enabled: false }}
        colors={colors}
        colorBy={'id'}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        maxValue={maxValue}
        minValue={minValue}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        enableLabel={enableLabel}
        tooltip={tooltip}
        borderRadius={3}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[]}
      />
    </div>
  );
}
