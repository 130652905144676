import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { TiArrowForwardOutline } from 'react-icons/ti';
import { HiBell, HiOutlineOfficeBuilding } from 'react-icons/hi';

import Select from 'react-select';
import { styleReset } from '../../lib/Select/style';
import styles from './Header.module.scss';
import Modale from '../../lib/Modale';
import ExportModale from '../ExportModale/ExportModale';
import { getStructuresListsAction, getStructuresAction } from '../../../actions/structuresActions';
import logo from '../../../assets/images/logo_blue.png';
import { SET_VISUALISATION_FILTERS } from '../../../actions/types';
import { periodsOptions, monthsArray } from '../../../constants/constants';

export default function Header() {
  const dispatch = useDispatch();
  const { userAuth, role } = useSelector((state) => state.auth);
  const { structuresList, loaders } = useSelector((state) => state.structures);
  const { structures, filters } = useSelector((state) => state.visualisation);
  const { pathname } = useLocation();
  const [modaleIsOpen, setModaleIsOpen] = useState();

  const options = {
    first: !filters.isMonth ? periodsOptions.y0 : periodsOptions.m0,
    second: !filters.isMonth ? periodsOptions.y1 : periodsOptions.m1,
  };

  const getData = useCallback(() => {
    getStructuresListsAction(dispatch);
    getStructuresAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, []);

  function handleChangeFilters(obj) {
    dispatch({
      type: SET_VISUALISATION_FILTERS,
      payload: { ...filters, ...obj },
    });
  }

  function getActiveStructure() {
    if (structures?.length) {
      return structures[0];
    }
    if (structuresList?.length) {
      return structuresList[0]._id;
    }
    return null;
  }

  function handleChangeCompareType(isMonth) {
    const data = { ...filters, isMonth };
    if (!isMonth) {
      data.m0 = '';
      data.m1 = '';
      const year = parseFloat(data.y0) - 1;
      data.y1 = year.toString();
    } else {
      const monthIndex = new Date().getMonth();
      if (monthIndex !== -1) {
        data.m0 = monthsArray[monthIndex];
        data.m1 = monthsArray[monthIndex];
        const year = parseFloat(data.y0) - 1;
        data.y1 = year.toString();
      }
    }
    handleChangeFilters(data);
  }

  return (
    <>
      <div className={styles.container}>
        <Link to={'/visualisation/synthese'}>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <div className={styles.background}>
          {role === 'Admin' || role === 'Owner' ? <div className={styles.nav}>
            <NavLink
              to={'/visualisation/synthese'}
              className={pathname.includes('/visualisation') ? styles.active : ''}
            >
              Visualisation
            </NavLink>
            <NavLink
              to={`/forms/${getActiveStructure()}`}
              className={pathname.includes('/forms') ? styles.active : ''}
            >
              Saisie de données
            </NavLink>
            </div>
            : <div></div>
          }
          {pathname.includes('/visualisation')
            && <div className={styles.period}>
              <div className={styles.current}>
                <div className={styles.toggle}>
                  <button
                    className={filters.isMonth ? styles.active : ''}
                    onClick={() => handleChangeCompareType(true)}
                  >
                    Mois
                  </button>
                  <button
                    onClick={() => handleChangeCompareType(false)}
                    className={!filters.isMonth ? styles.active : ''}
                  >
                    Année
                  </button>
                </div>
                <div className={styles.select}>
                  <Select
                    options={options?.first}
                    styles={styleReset}
                    value={options?.first.find(
                      (opt) => opt.value.y0 === filters.y0
                      && opt.value.m0 === filters.m0,
                    )}
                    onChange={(opt) => handleChangeFilters(opt.value)}
                  />
                </div>
              </div>
              <div className={styles.compare}>
                <div className={styles.select}>
                  <Select
                    options={options?.second}
                    styles={styleReset}
                    value={options?.second.find(
                      (opt) => opt.value.y1 === filters.y1
                      && opt.value.m1 === filters.m1,
                    )}
                    onChange={(opt) => handleChangeFilters(opt.value)}
                  />
                </div>
              </div>
            </div>
          }
          <div className={styles.links}>
            <button
              onClick={() => setModaleIsOpen(true)}
              className={styles.link}>
              <TiArrowForwardOutline size={22} /> <span className={styles['hide-pad']}>Exports</span>
            </button>
            {role === 'Admin'
              && <NavLink to={'/admin'}
                className={styles.link}
                activeClassName={styles['is-active']}>
                <HiOutlineOfficeBuilding size={22} /><span className={styles['hide-pad']}>Administration</span>
              </NavLink>
            }
            <Link
              to={'/profile'}
              className={styles.user}>
              <p>{userAuth?.profile?.firstName} {userAuth?.profile?.lastName.toUpperCase()}</p>
              <div className={styles.icon}>
                <HiBell size={24} />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Modale
        hideClose={loaders.export}
        isOpen={modaleIsOpen}
        modaleToggle={() => setModaleIsOpen(false)}
      >
      {modaleIsOpen
        && <ExportModale
          setModaleIsActive={setModaleIsOpen}
        />
      }
    </Modale>
    </>
  );
}
