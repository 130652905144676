import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFillingAction } from '../../../actions/visualisationActions';
import GroupedBarChart from '../../../components/partials/Charts/GroupedBarChart/GroupedBarChart';

import { colors } from '../../../constants/constants';

import styles from './RateFilling.module.scss';

export default function RateFilling() {
  const { filling, structures, period } = useSelector((state) => state.visualisation);
  const dispatch = useDispatch();

  const getData = useCallback(() => {
    getFillingAction(dispatch, { structures, period });
  }, [dispatch, structures]);

  useEffect(() => {
    if (structures) {
      getData();
    }
  }, [structures, period]);

  return (
    <div className={`page-content visualisation ${styles.container}`}>
      {filling && filling?.map((f) => (
          <div key={f.label} className={styles.block}>
            <GroupedBarChart
              data={f.data}
              colors={colors}
              unit={'%'}
              maxValue={100}
              legend
              indexBy='activity'
              keys={['Réel', 'Prévisionnel', 'Année N-1', 'Année N-2']}
              label={f.label}
              />
          </div>
      ))}
    </div>
  );
}
