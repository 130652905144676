import React from 'react';
import { format } from 'date-fns';
import styles from './GerRow.module.scss';
import { numberWithSpaces } from '../../../utils/utils';

export default function GerRow({
  expense,
}) {
  return (
    <div className={styles.container} >
      <div className={`${styles.col} ${styles.structure}`}>
        <p>{expense?.structure?.name}</p>
      </div>
      <div className={`${styles.col} ${styles.name}`}>
        <p>{expense.description}</p>
      </div>
      <div className={`${styles.col} ${styles.date}`}>
        <p>{expense.date && format(new Date(expense.date), 'dd/MM/yyyy')}</p>
      </div>
      <div className={`${styles.col} ${styles.amount}`}>
        <p>{numberWithSpaces(expense.amount)} €</p>
      </div>
    </div>
  );
}
