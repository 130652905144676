import React, { useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { IoArrowBackSharp } from 'react-icons/io5';

import { getStructureAction, putStructureAction } from '../../../actions/structuresActions';
import { GET_STRUCTURE, POST_USER, ERROR_STRUCTURE } from '../../../actions/types';

import UsersModale from '../../../components/partials/UserModale/UserModale';
import CheckList from '../../../components/partials/CheckList/CheckList';
import Modale from '../../../components/lib/Modale';
import Button from '../../../components/lib/Button';

import styles from './StructurePage.module.scss';
import UserRow from '../../../components/partials/UserRow/UserRow';
import CheckActivitiesList from '../../../components/partials/CheckActivitiesList/CheckActivitiesList';

export default function StructurePage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { structure, lists } = useSelector((state) => state.structures);
  const { toast } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [modaleIsOpen, setModaleIsOpen] = useState();
  const {
    setValue,
    handleSubmit,
  } = useForm();

  const getStructure = useCallback(() => {
    getStructureAction(dispatch, id);
    dispatch({
      type: ERROR_STRUCTURE,
      payload: null,
    });
    dispatch({
      type: POST_USER,
      payload: null,
    });
  }, [dispatch, id]);

  const clearStructure = useCallback(() => {
    dispatch({
      type: GET_STRUCTURE,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    getStructure();
    return () => clearStructure();
  }, []);

  useEffect(() => {
    if (toast?.message) {
      setIsLoading(false);
    }
  }, [toast]);

  function submitStructure(values) {
    setIsLoading(true);
    putStructureAction(dispatch, id, values);
    dispatch({
      type: POST_USER,
      payload: null,
    });
  }

  return (
    <div className={`page-content admin ${styles.container}`}>
      <header>
        <div className={styles.title}>
          <Link to={'/admin'}>
            <IoArrowBackSharp size={38} />
          </Link>
          <h1>{structure?.name}</h1>
        </div>
      </header>
      <div className={styles.users}>
        <label>Équipe</label>
        <div className={styles.list}>
          {structure?.users?.map((u) => <UserRow
            key={u._id}
            user={u}
            editUser={(val) => setModaleIsOpen(val)}
            />)}
        </div>
        <Button
          handleClick={() => setModaleIsOpen({})}
          >
          Ajouter un utilisateur
        </Button>
        <Modale
          isOpen={modaleIsOpen}
          modaleToggle={setModaleIsOpen}
          >
          {modaleIsOpen && <UsersModale
            structure={structure}
            setUserData={setModaleIsOpen}
            userData={modaleIsOpen}
            />}
        </Modale>
      </div>

      <CheckList
        label={'Consommation énergétique'}
        list={lists?.energy}
        handleChange={(val) => setValue('energies', val)}
        activeList={structure?.energies ? structure?.energies : []}
        />

      <CheckList
        label={'Liste des catégories'}
        list={lists?.categories}
        handleChange={(val) => setValue('categories', val)}
        activeList={structure?.categories ? structure?.categories : []}
        />

      <label>Liste des activités</label>
      {lists?.activities?.map((a) => <CheckActivitiesList
        key={a.type}
        label={a.type}
        list={a.subType}
        handleChange={(val) => setValue('activities', val)}
        activeList={structure?.activities ? structure?.activities : []}
        />)}
      <Button
        isLoading={isLoading}
        handleClick={handleSubmit(submitStructure)}
        >
        Enregistrer
      </Button>
    </div>
  );
}
