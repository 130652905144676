import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { getStructuresAction } from '../../../actions/structuresActions';

import InputText from '../../../components/lib/InputText';
import Button from '../../../components/lib/Button';
import Modale from '../../../components/lib/Modale';

import styles from './StructureListPage.module.scss';
import StructureRow from '../../../components/partials/StructureRow/StructureRow';
import CreateStructureModale from '../../../components/partials/CreateStructureModale/CreateStructureModale';
import { GET_STRUCTURE } from '../../../actions/types';

export default function StructureListPage() {
  const {
    control,
    watch,
  } = useForm();
  const dispatch = useDispatch();
  const { structuresList } = useSelector((state) => state.structures);

  const [filtreredList, setFilteredList] = useState([]);
  const [modaleIsOpen, setModaleIsOpen] = useState(false);

  const getStructures = useCallback(() => {
    getStructuresAction(dispatch);
    dispatch({
      type: GET_STRUCTURE,
      payload: null,
    });
  }, [dispatch]);

  useEffect(() => {
    getStructures();
  }, []);

  useEffect(() => {
    if (structuresList) {
      const list = structuresList.filter((s) => {
        if (!watch('search')) {
          return s;
        }
        if (s.name.toLowerCase().includes(watch('search').toLowerCase())) {
          return s;
        }
        return null;
      });
      setFilteredList(list);
    }
  }, [structuresList, watch('search')]);

  return (
    <div className={`page-content admin ${styles.container}`}>
      <header>
        <h1>Administration</h1>
        <Link to={'/admin/users'}>
          Gestion des administrateurs
        </Link>
      </header>
      <div className={styles.actions}>
        <div className={styles.search}>
          <InputText
            name='search'
            control={control}
            className={'primary'}
            placeholder={'Recherche'}
            />
        </div>
        <Button
          handleClick={() => setModaleIsOpen(true)}
          >
          Créer un nouveau site
        </Button>
      </div>
      <div className={styles.list}>
        {filtreredList?.map((s) => (<StructureRow key={s._id} structure={s} />))}
      </div>
      <Modale
        isOpen={modaleIsOpen}
        modaleToggle={setModaleIsOpen}
        >
        {modaleIsOpen && <CreateStructureModale />}
      </Modale>
    </div>
  );
}
