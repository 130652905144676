export const style = {
  container: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    backgroundColor: 'transparent',
    border: '2px solid #dddddd',
    fontWeight: 'bold',
    height: 50,
    '&:hover': {
      borderColor: state.isFocused ? '#d9d9d9' : '',
    },
  }),
  placeholder: (provided) => ({ ...provided, fontSize: '14px', fontWeight: 'bold' }),
  valueContainer: (provided) => ({ ...provided }),
  singleValue: (provided) => ({ ...provided, fontSize: '14px', fontWeight: 'bold' }),
  multiValue: (provided) => ({ ...provided, fontSize: '14px', fontWeight: 'bold' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#33B0C5',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#F2F2F2',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: state.isSelected ? '' : '',
    color: state.isSelected ? '#181844' : '#181844',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      color: '#181844',
    },
  }),
  clearIndicator: (provided) => ({ ...provided }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: (provided) => ({ ...provided }),
};

export const stylePrimary = {
  container: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    backgroundColor: 'transparent',
    border: '2px solid #ECECF0',
    fontWeight: 'bold',
    borderRadius: 9,
    height: 50,
    '&:hover': {
      borderColor: state.isFocused ? '#d9d9d9' : '',
    },
  }),
  placeholder: (provided) => ({ ...provided, fontSize: '14px', fontWeight: 'bold' }),
  valueContainer: (provided) => ({ ...provided }),
  singleValue: (provided) => ({ ...provided, fontSize: '14px', fontWeight: 'bold' }),
  multiValue: (provided) => ({ ...provided, fontSize: '14px', fontWeight: 'bold' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#33B0C5',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#F2F2F2',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: state.isSelected ? '' : '',
    color: state.isSelected ? '#181844' : '#181844',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      color: '#181844',
    },
  }),
  clearIndicator: (provided) => ({ ...provided }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: (provided) => ({ ...provided }),
};

export const styleReset = {
  container: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: '0 0 0 1px transparent',
    backgroundColor: '#FFFFFF',
    border: '2px solid #ECECF0',
    fontWeight: 'bold',
    borderRadius: 9,
    height: 41,
    '&:hover': {
      borderColor: state.isFocused ? '#d9d9d9' : '',
    },
  }),
  placeholder: (provided) => ({ ...provided, fontSize: '14px', fontWeight: 'bold' }),
  valueContainer: (provided) => ({ ...provided }),
  singleValue: (provided) => ({ ...provided, fontSize: '14px', fontWeight: 'bold' }),
  multiValue: (provided) => ({ ...provided, fontSize: '14px', fontWeight: 'bold' }),
  multiValueLabel: (provided) => ({ ...provided }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#33B0C5',
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#F2F2F2',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: state.isSelected ? '' : '',
    color: state.isSelected ? '#181844' : '#181844',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      color: '#181844',
    },
  }),
  clearIndicator: (provided) => ({ ...provided }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorContainer: (provided) => ({ ...provided }),
};
